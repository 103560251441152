<template>
    <div :style="`background-image:url(${src});height:${height}px;width:${width}px;background-size:${size};`"
        class="coverImage"></div>
</template>

<script>
    export default {
        props: ['src', 'width', 'height', 'size']
    }
</script>

<style scoped>
    .coverImage {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #f7f7f7;
    }
</style>