<template>
     <!-- left tab -->
     <div class="fixedTab1 flex_column_center_center" v-if="route.name != 'serviceChat'">

          <div class="f1 f1_top flex_column_center_center" :class="{ on: route.name == 'CartIndex' }"
               @click="operate('history')">
               <img class="f1_back_top" src="@/assets/member/history.png" alt="">
               <span class="f1_txt">{{ L['足迹'] }}</span>
          </div>
          <div class="f1 flex_column_center_center" :class="{ on: route.name == 'CartIndex' }" @click="operate('cart')">
               <img class="f1_back_top" src="@/assets/member/cart.png" alt="">
               <span class="f1_txt">{{ L['购物车'] }}</span>
          </div>
          <div class="f1 flex_column_center_center" :class="{ on: route.name == 'quickOrder' }" @click="operate('quick')">
               <img class="f1_back_top" src="@/assets/member/quick.png" alt="">
               <span class="f1_txt">{{ L['快速下单'] }}</span>
          </div>
          <div class="f1 flex_column_center_center" :class="{ on: route.name == 'optimize' }" @click="operate('opti')"
               v-if="memberInfo.memberType == 2">
               <img class="f1_back_top" src="@/assets/member/prototy.png" alt="">
               <span class="f1_txt">{{ L['型号转换'] }}</span>
          </div>
          <div class="f1 flex_column_center_center" :class="{ on: route.name == 'sampleApply' }"
               @click="operate('sampleApply')" v-if="memberInfo.memberType == 2">
               <img class="f1_back_top" src="@/assets/member/collect.png" alt="">
               <span class="f1_txt">{{ L['样品申请'] }}</span>
          </div>

          <div class="f1 flex_column_center_center" :class="{ on: route.name == 'init_inquiry' }"
               @click="operate('inquiry')">
               <img class="f1_back_top" src="@/assets/inquiry/inq_main_icon.png" alt="">
               <!-- <span :class="{'el-icon-star-on':true,f1_icon:true}"></span> -->
               <span class="f1_txt">{{ L['发布询盘'] }}</span>
          </div>
          <div class="f1 flex_column_center_center" :class="{ on: route.name == 'MemberAuthen' }" @click="authen"
               v-if="memberInfo.memberType == 1">
               <img class="f1_back_top" src="@/assets/member/authen.png" alt="">
               <span class="f1_txt">{{ L['企业认证'] }}</span>
          </div>
          <div class="f1 f1_mid flex_column_center_center" :class="{ on: route.name == 'serviceChat' }"
               @click="operate('service')">
               <img class="f1_back_top" src="@/assets/member/service.png" alt="">
               <!-- <span :class="{'el-icon-headset':true,f1_icon:true}"></span> -->
               <span class="f1_txt">{{ L['平台客服'] }}</span>
          </div>
          <div class="f1 f1_bot flex_column_center_center" @click="backTop">
               <img class="f1_back_top" src="@/assets/member/top.png" alt="">
               <span class="f1_txt">{{ L['返回顶部'] }}</span>
          </div>

          <el-dialog v-model="dialog" width="532px">
               <div class="mod1 flex-col">

                    <div>
                         <div class="bd1 flex-row justify-between">
                              <span class="word1">{{ L['提示'] }}</span>
                              <img class="label1" referrerpolicy="no-referrer" src="@/assets/inquiry/inq_icon11.png"
                                   @click="dialog = false" />
                         </div>
                         <div class="bd2 flex-row">
                              <span class="word2">{{ L['暂不满足询价功能使用条件。满足以下条件后方可使用。'] }}</span>
                         </div>


                         <div class="bd4 flex_column_center_center">
                              <div class="bd3 flex_row_between_center"
                                   v-for="({ description, pass }, index) in inquiryCondition.pur_conditions" :key="index">
                                   <div class="flex-row">
                                        <div class="layer1 flex-col"></div>
                                        <span class="info1">{{ description }}</span>
                                   </div>

                                   <div class="flex_row_center_center">
                                        <img class="label2" :src="require(`@/assets/inquiry/inq_icon${pass ? 6 : 7}.png`)" />
                                        <span class="word3">{{ pass ? L['已满足'] : L['未满足'] }}</span>
                                   </div>
                              </div>
                         </div>
                    </div>




                    <div class="bd6 flex-row">
                         <div class="mod2 flex-col" @click="dialog = false"><span class="txt2">{{ L['我已知晓'] }}</span></div>
                    </div>
               </div>
          </el-dialog>

     </div>
     <!-- left tab -->
</template>

<script>
import { ref, getCurrentInstance, reactive, computed, onMounted } from 'vue';
import { watchEffect } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus'
export default {
     setup(props) {
          const route = useRoute()
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const router = useRouter()
          const store = useStore()
          const memberInfo = ref(store.state.memberInfo);
          const searchBarFixed = ref(false)
          const adminInfo = computed(() => {
               return {
                    avatar: store.state.configInfo.platform_customer_service_logo,
                    name: store.state.configInfo.platform_customer_service_name
               }
          })
          const inquiryCondition = reactive({
               spl_conditions: [],
               spl_pass: '',
               pur_conditions: [],
               pur_pass: '',

          })
          const dialog = ref(false)
          const operate = (type) => {
               let newWin
               switch (type) {
                    case 'center': {
                         router.push('/member/index')
                         break;
                    }
                    case 'history': {
                         router.push('/member/footprint')
                         break;
                    }
                    case 'cart': {
                         router.push('/cart/index')
                         break
                    }
                    case 'quick': {
                         newWin = router.resolve({
                              path: '/quickOrder'
                         })
                         break
                    }
                    case 'opti': {
                         newWin = router.resolve({
                              path: '/prototype/optimize/index'
                         })
                         break
                    }
                    case 'sampleApply': {
                         newWin = router.resolve({
                              path: '/prototype/sample/apply'
                         })
                         break
                    }

                    case 'inquiry': {
                         clickCheck()
                         return
                    }

                    case 'service': {

                         let chatInfo = {
                              storeId: 0,
                              vendorAvatar: adminInfo.value.avatar,
                              storeName: adminInfo.value.name,
                              showData: {},
                              source: '从商城悬浮栏进入'
                         }
                         store.commit('saveChatBaseInfo', chatInfo)

                         newWin = router.resolve({
                              path: '/service',
                              query: {
                                   vid: 0
                              }
                         })
                         break
                    }

               }
               window.open(newWin.href, '_blank')
          }

          watchEffect(() => {
               memberInfo.value = store.state.memberInfo;
               window.onscroll = (e) => {
                    var height = 600;
                    var scrollTop =
                         window.pageYOffset ||
                         document.documentElement.scrollTop ||
                         document.body.scrollTop;
                    if (scrollTop > height) {
                         searchBarFixed.value = true;
                    } else {
                         searchBarFixed.value = false;
                    }
               }
          });

          //询价使用条件查询
          const conditionCheck = () => {
               if (store.state.loginFlag) {
                    proxy.$get('v3/business/front/purchaseInquire/conditionCheck').then(res => {
                         if (res.state == 200) {
                              inquiryCondition.pur_conditions = res.data.conditions
                              inquiryCondition.pur_pass = res.data.pass
                              store.commit('purchaserChecking', { pass: inquiryCondition.pur_pass })
                         }
                    })

                    proxy.$get('v3/business/front/supplierInquire/conditionCheck').then(res => {
                         if (res.state == 200) {
                              inquiryCondition.spl_conditions = res.data.conditions
                              inquiryCondition.spl_pass = res.data.pass
                              store.commit('supplierChecking', { pass: inquiryCondition.spl_pass })
                         }
                    })
               }

          }



          const clickCheck = () => {

               if (!store.state.loginFlag) {
                    router.push('/login')
                    return
               }

               if (!inquiryCondition.pur_pass) {
                    setTimeout(() => {
                         dialog.value = true
                    }, 100)
               } else {
                    dialog.value = false
                    router.push('/inquiry/purchaser/init_inquiry')
               }
          }



          const backTop = () => {
               window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
               })
          };

          const authen = () => {
               router.push(`/member/authen`);
          };

          router.afterEach((to, from) => {
               if (from.path == '/login') {
                    conditionCheck()
               }
          })


          onMounted(() => {
               conditionCheck()
          })

          return {
               clickCheck,
               operate,
               route,
               memberInfo,
               searchBarFixed,
               backTop,
               authen,
               inquiryCondition,
               dialog,
               L
          }
     }
}

</script>

<style lang="scss">
.fixedTab1 {
     .el-dialog__body {
          padding: 0;

     }

     .el-dialog__header {
          display: none;
     }
}
</style>



<style lang="scss" scoped>
.fixedTab1 {
     position: fixed;
     right: 0;
     bottom: 35px;
     margin-right: 10px;
     width: 60px;
     z-index: 1999;

     .f1 {
          position: relative;
          width: 66px;
          padding-top: 7px;
          padding-bottom: 7px;
          background-color: rgba(0, 0, 0, 0.5);
          transition: all .3s ease;

          &.f1_top {
               border-top-left-radius: 3px;
               border-top-right-radius: 3px;
          }

          &.f1_mid {
               border-bottom-left-radius: 3px;
               border-bottom-right-radius: 3px;
          }

          &.f1_bot {
               margin-top: 10px;
               border-radius: 3px;
          }

          .f1_icon,
          .f1_back_top {
               opacity: 0.9;
               -webkit-transition: -webkit-transform 0.5s;
               transition: -webkit-transform 0.5s;
               -moz-transition: transform 0.5s, -moz-transform 0.5s;
               transition: transform 0.5s;
               transition: transform 0.5s, -webkit-transform 0.5s, -moz-transform 0.5s;
          }

          .f1_back_top {
               cursor: pointer;
               width: 25px;
               height: 25px;
          }

          span:first-child {
               font-size: 24px;
               cursor: pointer;
               color: #fff;
          }

          .f1_txt {
               font-size: 12px;
               font-family: SourceHanSansCN-Regular, SourceHanSansCN;
               font-weight: 400;
               color: #fff;
               margin-top: 6px;
               white-space: nowrap;
               cursor: default;
               opacity: .9;
          }

          &:hover {

               .f1_icon,
               .f1_back_top {
                    opacity: 1;
                    -webkit-transform: scale(1.1, 1.1);
                    transform: scale(1.1, 1.1);
               }

               background: #626262;

               span:first-child,
               .f1_txt {
                    color: #ffffff;
               }
          }

          #qrcode:hover+.mobile_order_model {
               display: block;
          }

          .mobile_order_model {
               display: none;
               position: absolute;
               left: 39px;
               background-color: #fff;
               padding: 10px;
               z-index: 100;
          }
     }
}



.mod1 {
     background-color: rgba(255, 255, 255, 1);
     border-radius: 6px;
     min-height: 345px;
     width: 532px;
     justify-content: space-between;

     .bd1 {
          height: 34px;
          margin: 20px 0 0 243px;
          justify-content: space-between;

          .word1 {
               width: 45px;
               height: 23px;

               color: rgba(0, 0, 0, 1);
               font-size: 22px;
               font-family: MicrosoftYaHei-Bold;
               text-align: left;
               white-space: nowrap;
               line-height: 23px;
               margin-top: 11px;
               display: block;
          }

          .label1 {
               width: 16px;
               height: 16px;
               margin-right: 16px;
          }
     }

     .bd2 {
          width: 404px;
          height: 16px;
          margin: 31px 0 0 59px;

          .word2 {
               width: 404px;
               height: 16px;

               color: rgba(18, 18, 18, 1);
               font-size: 16px;
               font-family: MicrosoftYaHei;
               text-align: center;
               white-space: nowrap;
               line-height: 16px;
               display: block;
          }
     }

     .bd4 {
          margin: 45px 0 0 0px;

     }


     .bd3 {
          width: 345px;
          height: 18px;
          margin-bottom: 20px;

          .layer1 {
               background-color: rgba(102, 102, 102, 1);
               border-radius: 50%;
               width: 6px;
               height: 6px;
               margin-top: 6px;
          }

          .info1 {
               width: 57px;
               height: 13px;

               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               font-family: MicrosoftYaHei;
               text-align: left;
               white-space: nowrap;
               line-height: 14px;
               display: block;
               margin: 3px 0 0 11px;
          }

          .label2 {
               width: 18px;
               height: 18px;
          }

          .word3 {
               width: 43px;
               height: 12px;

               color: rgba(102, 102, 102, 1);
               font-size: 14px;
               font-family: MicrosoftYaHei;
               text-align: left;
               white-space: nowrap;
               line-height: 14px;
               display: block;
               margin: 0px 0 0 6px;
          }
     }


     .bd6 {
          width: 127px;
          height: 36px;
          margin: 48px 0 28px 203px;
          cursor: pointer;

          .mod2 {
               background-color: rgba(8, 113, 243, 1);
               border-radius: 4px;
               height: 36px;
               width: 127px;

               .txt2 {
                    width: 66px;
                    height: 16px;

                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    font-family: MicrosoftYaHei;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 16px;
                    display: block;
                    margin: 10px 0 0 30px;
               }
          }
     }
}
</style>