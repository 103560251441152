/*
* 语言文件 en：英文
* */
export const lang_en = {
    //首页公共头部最顶部
    '查看合同': '',
    '全部分类': '',
    '我的订单': '',
    '会员信息': '',
    '发票管理': '',
    '授信申请': '',
    '添加收货地址': '',
    '请输入收货人姓名': '',
    '编辑地址': '',
    '请输入手机号': '',
    '收货人': '',
    '电话号码': '',
    '请选择所在地区': '',
    '所在地区': '',
    '请输入详细地址': '',
    '详细地址': '',
    '设为默认地址': '',
    '确 定': '',
    '请输入收货人': '',
    '请输入2~25个字符': '',
    '请输入5~40个字符': '',
    '取 消': '',
    '请输入正确的电话号码': '',
    '暂无数据': '',
    '添加至我的项目': '',
    '取消': '',
    '立即认证': '',
    '创建项目': '',
    '确定': '',
    '行业知识': '',
    '供应商': '',
    '新品速递': '',
    '更多': '',
    '国产替代': '',
    '购物车中还没有产品，赶紧选购吧': '',
    '共': '',
    '删除': '',
    '最新加入的产品': '',
    '件产品': '',
    '快速下单': '',
    '去购物车': '',
    '设为特殊关注': '',
    '删除成功！': '',
    '搜索结果': '',
    '取消特殊关注': '',
    '取消关注': '',
    '件': '',
    '进入供应商': '',
    '热销推荐': '',
    '折': '',
    '本月上新': '',
    '领取': '',
    '已抢完': '',
    '使用规则': '',
    '已领取': '',
    '随机券': '',
    '已抢': '',
    '已抢光': '',
    '立即使用': '',
    '立即领取': '',
    '请选择地址': '',
    '领取成功': '',
    '常用地址': '',
    '配送至': '',
    '其他地址': '',
    '免运费': '',
    '运费': '',
    '足迹': '',
    '型号转换': '',
    '样品申请': '',
    '购物车': '',
    '企业认证': '',
    '发布询盘': '',
    '平台客服': '',
    '提示': '',
    '返回顶部': '',
    '暂不满足询价功能使用条件。满足以下条件后方可使用。': '',
    '未满足': '',
    '我已知晓': '',
    '已满足': '',
    '合作伙伴：': '',
    '版权所有': '',
    '工作时间：周一 ～ 周五': '',
    '提供技术支持': '',
    '包含分类': '',
    '暂无分类': '',
    '已选参数：': '',
    '暂无特性': '',
    '清除筛选': '',
    '产品系列': '',
    '编辑寄送地址': '',
    '个': '',
    '加入对比': '',
    '添加寄送地址': '',
    '阅读': '',
    '请输入5~60个字符': '',
    '请输入正确的详细地址': '',
    '添加邮箱': '',
    '编辑邮箱': '',
    '邮箱': '',
    '设为默认邮箱': '',
    '请输入邮箱': '',
    '请输入邮箱号': '',
    '请输入正确的邮箱': '',
    '向上滑动浏览更多数据': '',
    '数据加载完毕~': '',
    '数据加载中...': '',
    '手机登录': '',
    '请输入账号/手机号': '',
    '账号登录': '',
    's后获取': '',
    '获取验证码': '',
    '请输入验证码': '',
    '请输入密码': '',
    '二维码已失效': '',
    '点击刷新': '',
    '登录成功': '',
    '打开手机商城，扫描二维码': '',
    '登录': '',
    '更安全': '',
    '反应快': '',
    '免输入': '',
    '立即注册': '',
    '忘记密码': '',
    '请输入短信验证码': '',
    '请输入正确的账号/手机号': '',
    '采购管理': '',
    '交易评价': '',
    '我的项目': '',
    '样品订单': '',
    '采购申请': '',
    '常购清单': '',
    '设计师申请单': '',
    '物料编码': '',
    '我的询价': '',
    '我的报价': '',
    '合约订单': '',
    '我的招标': '',
    '投标邀请': '',
    '财务': '',
    '信用账单': '',
    '发票': '',
    '企业管理': '',
    '合同': '',
    '售后服务': '',
    '子账号管理': '',
    '关注中心': '',
    '退货/退款': '',
    '我的收藏': '',
    '供应商店铺': '',
    '我的足迹': '',
    '我的退款': '',
    '客户服务': '',
    '账户信息': '',
    '我的退货': '',
    '设置': '',
    '地址管理': '',
    '账户安全': '',
    '会员中心': '',
    '手机号管理': '',
    '邮箱管理': '',
    '账号安全': '',
    '登录密码': '',
    '产品收藏': '',
    '支付密码': '',
    '重置密码': '',
    '文章收藏': '',
    '收货地址': '',
    '我的钱包': '',
    '我的余额': '',
    '账户充值': '',
    '我的优惠券': '',
    '我的积分': '',
    '资产管理': '',
    '首页': '',
    '账户管理': '',
    '用户中心': '',
    '消息': '',
    '请输入产品名称/型号/物料编码': '',
    '搜索': '',
    '我的购物车': '',
    '接收设置': '',
    '样品申请单': '',
    '消息列表': '',
    '全部产品分类': '',
    '您好，欢迎来到': '',
    '设计师': '',
    '个人': '',
    '业务员': '',
    '请登录': '',
    '企业': '',
    '退出': '',
    '免费注册': '',
    '待付款订单': '',
    '待收货订单': '',
    '商城首页': '',
    '供应商收藏': '',
    '小程序': '',
    '商务联系电话：': '',
    '供应商联系电话：': '',
    '编辑项目': '',
    '项目名称：': '',
    '请输入项目名称': '',
    '项目周期：': '',
    '至': '',
    '开始日期': '',
    '结束日期': '',
    '项目描述：': '',
    '请输入1~20个字符': '',
    '请输入项目描述': '',
    '请选择项目周期': '',
    '请输入正确的项目名称': '',
    '半成品': '',
    '待产': '',
    '发货日：': '',
    '现货': '',
    '最低限购': '',
    '起订量': '',
    '天': '',
    '加入购物车': '',
    '加入样品申请单': '',
    '加入项目': '',
    '超过购买限制': '',
    '数量小于最小起订量': '',
    '订货编码：': '',
    '新品推荐': '',
    '样品申请单中还没有产品，赶紧选购吧': '',
    '去样品申请单': '',
    '最新加入的样品': '',
    '秒杀': '',
    '距离开始剩余：': '',
    '取消提醒': '',
    '距离结束剩余：': '',
    '提醒我': '',
    '暂时没有数据～': '',
    '人购买': '',
    '查看更多': '',
    '立即抢购': '',
    '添加标题': '',
    '添加内容': '',
    '此处添加【403*高度不限】图片': '',
    '图片标题': '',
    '此处添加【186*340】图片': '',
    '图片子标题': '',
    '此处添加【187*120】图片': '',
    '请上传500*80的图片': '',
    '此处添加【172*106】图片': '',
    '平台还未设置资讯': '',
    '此处添加【360*560】图片': '',
    '该场景还没有产品': '',
    '此处添加产品': '',
    '请输入文章标题': '',
    '产品': '',
    '个产品': '',
    '库存：': '',
    '库存不足': '',
    '秒杀价': '',
    '合计': '',
    '价格': '',
    '已选': '',
    '已选清单': '',
    '暂无可选清单': '',
    '对比栏': '',
    '隐藏': '',
    '对比': '',
    '您还可以继续添加': '',
    '请添加对比条目': '',
    '清空对比栏': '',
    '已加入对比': '',
    '系列': '',
    '该': '',
    '对比已达到4个': '',
    '文章': '',
    '暂无文章数据': '',
    '最新文章': '',
    '招标信息': '',
    '文章分类': '',
    '招标主题': '',
    '项目实施地区：': '',
    '重置': '',
    '货物招标': '',
    '服务招标': '',
    '工程招标': '',
    '中标公告': '',
    '招标公告': '',
    '图片': '',
    '终止公告': '',
    '下载': '',
    '文档': '',
    '招标编号': '',
    '招标类型': '',
    '招标方式': '',
    '招标单位': '',
    '单位所在地址': '',
    '预算': '',
    '联系方式': '',
    '项目实施地区': '',
    '招标截止时间': '',
    '公告内容': '',
    '附件': '',
    '开标时间': '',
    ' 元': '',
    '招标方式：': '',
    '查看全部': '',
    '发布招标公告': '',
    '公开招标': '',
    '受邀投标方：': '',
    '邀请招标': '',
    '添加投标方': '',
    '序号': '',
    '企业名称': '',
    '操作': '',
    '招标类型：': '',
    '元': '',
    '预算金额：': '',
    '投标截止时间：': '',
    '请选择': '',
    '设置为投标截止时间': '',
    '开标时间：': '',
    '公告内容：': '',
    '附件：': '',
    '最多可添加5个文件。支持添加Word、PDF、Excel等文件格式。': '',
    '确认发布': '',
    '开标时间需大于投标截止时间': '',
    '请优先设置投标截止时间': '',
    '请填写': '',
    '添加附件': '',
    '请填写预算金额': '',
    '请选择投标截止时间': '',
    '请选择开标时间': '',
    '请选择项目实施地区': '',
    '请填写公告内容': '',
    '最多上传5个附件': '',
    '发布终止公告': '',
    '请选择受邀方': '',
    '招标主题：': '',
    '请填写公告标题': '',
    '公告标题：': '',
    '招标编号：': '',
    '发布中标公告': '',
    '招标单位：': '',
    '查看详情': '',
    '添加受邀投标方': '',
    '请输入企业名称': '',
    '企业名称：': '',
    '全选': '',
    '请选择投标方': '',
    '的招标邀请': '',
    '是否确认删除该条招标信息？': '',
    '付款': '',
    '受邀投标方': '',
    '支付成功': '',
    '收货信息': '',
    '确认订单': '',
    '更换地址': '',
    '新建地址': '',
    '发货方式：': '',
    '齐套发货': '',
    '分批发货': '',
    '单价（元）': '',
    '产品信息': '',
    '数量': '',
    '虚拟': '',
    '发货日': '',
    '需询价': '',
    '小计（元）': '',
    '请输入产品名称': '',
    '请输入产品自定义型号': '',
    '您的物料信息：': '',
    '保存': '',
    '编辑': '',
    '请': '',
    '填写': '',
    '您的物料编号信息': '',
    '赠品': '',
    '优惠券': '',
    '暂无可用优惠券': '',
    '不使用优惠券': '',
    '赠完为止': '',
    '备注信息': '',
    '优惠': '',
    '采购单号：': '',
    '请备注贵公司的标准采购单号': '',
    '请输入备注信息，如没有特殊要求可不填写': '',
    '备注：': '',
    '期望交货日期：': '',
    '（此日期仅作参考。如产品发货日不满足期望交货日期则订单需供应商审核通过后再进行付款。）': '',
    '￥': '',
    '产品金额：': '',
    '运费：': '',
    '优惠：': '',
    '小计：': '',
    '平台优惠券': '',
    '折扣券': '',
    '可用优惠券': '',
    '满减券': '',
    '随机金额券': '',
    '不可用优惠券': '',
    '使用规则：': '',
    '暂无优惠券': '',
    '固定金额券': '',
    '产品总额': '',
    '提交订单': '',
    '¥': '',
    '返回': '',
    '应付总额：': '',
    '移除无货产品': '',
    '使用积分': '',
    '提交修改': '',
    '积分': '',
    '可用积分': '',
    '抵扣': '',
    '订单大于等于': '',
    '使用': '',
    '暂不使用积分': '',
    '可用': '',
    '积分支付不超过订单金额的': '',
    '积分使用数量为': '',
    '的整数倍': '',
    '商品促销信息发生变化，请返回后重新下单': '',
    '积分等于': '',
    '商品促销信息发生变化': '',
    '请选择发货方式': '',
    '请新建收货地址': '',
    '操作成功，待供应商审核': '',
    '提交订单失败，请稍后重试': '',
    ',2s后自动跳转订单列表': '',
    '请输入正确的采购单号!': '',
    '发货日:': '',
    '请输入': '',
    '请填写正确的税号': '',
    '邮箱格式不正确，请重新输入！': '',
    '请填正确填写收票人电话': '',
    '请填正确填写注册电话': '',
    '请填写发票抬头': '',
    '请填写部门名称': '',
    '请填写纳税人税号': '',
    '请填写收票邮箱': '',
    '"请填写注册地址"': '',
    '请填写开户银行': '',
    '请填写注册电话': '',
    '请填写收票人姓名': '',
    '请填写银行账户': '',
    '请填写收票人电话': '',
    '产品明细': '',
    '请填写收票人地址': '',
    '产品类别': '',
    '请输入正确的': '',
    '收货地址：': '',
    '应付金额：': '',
    '支付方式': '',
    '在线支付': '',
    '其他银行': '',
    '收货人：': '',
    '请输入银行卡号': '',
    '汇款信息': '',
    '订单号：': '',
    '待支付金额：': '',
    '收款账户：': '',
    '开户名称：': '',
    '开户银行：': '',
    '注意事项（转账必读）': '',
    '公司转账汇款时请将订单号': '',
    '填写至汇款单中“用途/备注/摘要”等栏': '',
    '务必保证转账金额与订单金额一致，请勿多转/少转或分次转账。': '',
    '支付宝': '',
    '点击上传凭证': '',
    '汇款凭证：': '',
    '微信': '',
    '扫码完成支付': '',
    '刷新': '',
    '可支付': '',
    '可用额度：': '',
    '未设置支付密码': '',
    '立即设置': '',
    '不可支付': '',
    '立即付款': '',
    '剩余付款时间：': '',
    '请选择支付方式': '',
    '请输入支付密码': '',
    '支付成功,2s后自动跳转订单列表': '',
    '支付单已过期': '',
    '付款失败': '',
    '请上传凭证': '',
    '单价': '',
    '失效': '',
    '移入收藏夹': '',
    '默认': '',
    '库存': '',
    '失效产品': '',
    '确定删除选中产品？': '',
    '清空失效的产品': '',
    '导出已选商品快速下单清单': '',
    '删除选中的产品': '',
    '提交需求': '',
    '产品已选购': '',
    '套': '',
    '去结算': '',
    '这里空空如也，快去首页逛逛吧～': '',
    '去首页': '',
    '提交清单': '',
    '确定清空失效产品？': '',
    '件!': '',
    '数量超出购买范围！': '',
    '购买套数达到最大值': '',
    '超过购买限制!': '',
    '清空失效产品成功！': '',
    '移入收藏夹成功！': '',
    '购买套数最少为1套': '',
    '产品：': '',
    '提交产品不能为空！': '',
    '起订量为': '',
    '结算产品不能为空！': '',
    '请选择要删除的产品': '',
    '导出清单': '',
    '本月剩余可申请次数：': '',
    '次': '',
    '本月剩余申请金额：': '',
    '本月可申请总金额：': '',
    '剩余': '',
    '已选择': '',
    '样品价值': '',
    '超额': '',
    '本月剩余额度': '',
    '可申请额度不足': '',
    '可申请次数不足': '',
    '申请': '',
    '本月剩余可申请次数': '',
    '最低申请': '',
    '数量超出限制!': '',
    '数量超出范围！': '',
    '系列对比结果': '',
    '品牌中心': '',
    '高亮不同项': '',
    '产品对比结果': '',
    '隐藏相同项': '',
    '系列信息': '',
    '规格值': '',
    '基本信息': '',
    '属性值': '',
    '生产状态': '',
    '销售信息': '',
    '产品服务': '',
    '产品详情': '',
    '评价': '',
    '手机下单': '',
    '已关注': '',
    '联系客服': '',
    '收藏': '',
    '已收藏': '',
    '技术负责人': '',
    '分享': '',
    '新浪': '',
    '分享到微信': '',
    '新品': '',
    '上市时间：': '',
    '全部': '',
    '物料编码：': '',
    '领取优惠券': '',
    '起订': '',
    '产品已下架，欢迎挑选其他产品~': '',
    '加入我的项目': '',
    '产品已下架': '',
    '类似产品推荐': '',
    '产品介绍': '',
    '收起全部': '',
    '应用领域：': '',
    '新品特性：': '',
    '产品规格书：': '',
    '品牌': '',
    '可替代型号：': '',
    '常见问题：': '',
    '规格型号': '',
    '暂无产品服务~': '',
    '评分': '',
    '该产品暂无详情~': '',
    '好评率': '',
    '中评': '',
    '有图': '',
    '好评': '',
    '商家回复:': '',
    '差评': '',
    '该商品暂无评论~': '',
    '清空': '',
    '有物料编码': '',
    '描述': '',
    '产品名称': '',
    '购买数量': '',
    '新品名称/型号': '',
    '应用领域': '',
    '产品分类': '',
    '该新品的系列Id不存在': '',
    '暂无符合条件的产品': '',
    '详情介绍：': '',
    '我们正在努力装修中，敬请期待～': '',
    '精选': '',
    '参数描述': '',
    '关注': '',
    '为你推荐好券': '',
    '下一条：': '',
    '上一条：': '',
    '行业知识首页': '',
    '热门推荐': '',
    '接受合约时间还剩下': '',
    '合约详情': '',
    '小时': '',
    '分，到期未接受则该合约失效。': '',
    '合约单号：': '',
    '发起时间：': '',
    '合约状态': '',
    '手机号：': '',
    '所在地区：': '',
    '详细地址：': '',
    '产品及费用清单': '',
    '产品图片': '',
    '未税单价(元)': '',
    '税率': '',
    '单价(元)': '',
    '收费项': '',
    '报价(元)': '',
    '小计': '',
    '未税报价(元)': '',
    '合计金额：': '',
    '含税：': '',
    '优惠金额：': '',
    '合约接受截止时间：': '',
    '合约金额：': '',
    '交易合约信息': '',
    '最晚交付日期：': '',
    '买方：': '',
    '结算方式：': '',
    '卖方：': '',
    '发票类型：': '',
    '配送方式：': '',
    '其他约定：': '',
    '其他约定': '',
    '确认接受该合约？/n请确认对合约信息无异议后再接受合约。': '',
    '接受合约': '',
    '我再看看': '',
    '增值税专用发票': '',
    '询盘标题': '',
    '增值税普通发票': '',
    '商机金额': '',
    '无需发票': '',
    '最小值': '',
    '状态': '',
    '收货地区': '',
    '最大值': '',
    '暂无询价': '',
    '已报价': '',
    '未报价': '',
    '编辑报价': '',
    '报价信息': '',
    '产品报价': '',
    '发起报价': '',
    '规格/型号': '',
    '含税': '',
    '小计(元)': '',
    '添加产品': '',
    '报价(元）': '',
    '其他费用': '',
    '未含税': '',
    '添加收费项': '',
    '未税价：': '',
    '合计：': '',
    '交易信息': '',
    '全额付款': '',
    '定金发货': '',
    '分期付款': '',
    '其他': '',
    '联系人姓名：': '',
    '发票要求：': '',
    '联系人信息': '',
    '联系人手机号：': '',
    '其他说明': '',
    '指定配送方式：': '',
    '买方自提': '',
    '预计到货：': '',
    '供方承运': '',
    '达成合约后': '',
    '其他说明：': '',
    '可输入产品的详情情况介绍或其他被备注说明': '',
    '确': '',
    '认': '',
    '请填写产品数量': '',
    '请填写正确的税率': '',
    '请填写正确的产品单价': '',
    '请填写单位': '',
    '请输入正确报价': '',
    '请输入收费项': '',
    '请输入正确的税率': '',
    '请填写结算方式': '',
    '请填写配送方式': '',
    '请输入联系人姓名': '',
    '格式错误，请输入正确的手机号码': '',
    '已获得报价数': '',
    '请输入正确的合约天数': '',
    '等共': '',
    '截止时间：': '',
    '商机金额：': '',
    '联系人：': '',
    '电话：': '',
    '修改报价': '',
    '去报价': '',
    '确认接受该合约？\n请确认对合约信息无异议后再接受合约。': '',
    '询盘标题：': '',
    '询盘需求': '',
    '产品清单': '',
    '添': '',
    '期望单价(元)': '',
    '加': '',
    '报价截止时间': '',
    '期望收货日期': '',
    '详情描述：': '',
    '请输入您对产品功能、规格、使用场景或其他方面的要求': '',
    '（支持添加doc、docx、xls、xlsx、pdf、jpg、jpeg、png格式的文件）': '',
    '参考文件：': '',
    '确认': '',
    '最多上传三个附件': '',
    '请输入询盘标题': '',
    '发布': '',
    '请输入产品数量': '',
    '必须为大于0的正数': '',
    '请填写期望单价': '',
    '期望单价的最大值不能小于最小值': '',
    '请选择报价截止日期': '',
    '请选择期望收货日期': '',
    '请选择收货地址': '',
    '询价详情': '',
    '询盘状态：': '',
    '请填写详细地址': '',
    '发布时间：': '',
    '报价截止时间：': '',
    '审核拒绝原因：': '',
    '预计采购金额：': '',
    '询盘详情': '',
    '收起': '',
    '展开全部': '',
    '指定发货方式：': '',
    '报价列表': '',
    '期望收货日期：': '',
    '报价总金额': '',
    '供应方': '',
    '报价产品': '',
    '收货人信息': '',
    '报价时间': '',
    '洽谈': '',
    '查看报价': '',
    '接受报价': '',
    '官方推荐商品': '',
    '供应商信息': '',
    '确认接受该报价？': '',
    '联系电话：': '',
    '状态：': '',
    '报价详情': '',
    '报价时间：': '',
    '报价供应方：': '',
    '手机号码：': '',
    '合约状态:': '',
    '订单详情': '',
    '编辑合约': '',
    '请填写正确的单价': '',
    '请选择正确的合约接受截止时间': '',
    '发起合约': '',
    '请选择正确的最晚交付日期': '',
    '报价状态：': '',
    '默认地址': '',
    '详细信息报价后可见': '',
    '新增地址': '',
    '设置为默认': '',
    '删除地址': '',
    '全部地址': '',
    '确定删除该地址?': '',
    '暂无收货地址～': '',
    '确定取消收藏吗?': '',
    '取消收藏': '',
    '取消收藏成功': '',
    '补充以下信息完成认证': '',
    '取消收藏失败': '',
    '公司名称': '',
    '请输入公司名称': '',
    '请输入设计师姓名': '',
    '设计师姓名': '',
    '认证审核中...': '',
    '已认证为设计师用户！': '',
    '重新申请': '',
    '审核未通过': '',
    '请选择供应商': '',
    '信用帐期': '',
    '请输入正确的公司名称': '',
    '信用账期服务，先采购后付款': '',
    '企业会员权益': '',
    '折扣价': '',
    '物料管理': '',
    '产品折扣价采购': '',
    '管理本公司的设计师人员': '',
    '组织管理': '',
    '海量产品物料管理': '',
    '公司名称：': '',
    '申请认证': '',
    '公司信息': '',
    '部门名称：': '',
    '请输入部门名称': '',
    '社会统一信用代码：': '',
    '请输入社会统一信用代码': '',
    '请输入公司详细地址': '',
    '所在地：': '',
    '请输入联系人名称': '',
    '请输入联系人手机号': '',
    '企业邮箱：': '',
    '营业执照信息': '',
    '营业执照：': '',
    '请输入企业邮箱': '',
    '法人身份信息': '',
    '身份证人像页：': '',
    '身份证国徽页：': '',
    '提交': '',
    '认证成功！': '',
    '审核中…': '',
    '认证失败！': '',
    '失败原因：': '',
    '重新认证': '',
    '请输入正确的社会统一信用代码': '',
    '请上传身份证人像页': '',
    '请上传小于20M的图片': '',
    '请上传营业执照': '',
    '请上传身份证国徽页': '',
    '发布招标': '',
    '待投标': '',
    '待平台审核': '',
    '终止招标': '',
    '已结束': '',
    '查看终止公告': '',
    '已终止': '',
    '查看中标公告': '',
    '暂无报价': '',
    '确认删除该招标？': '',
    '确认结束报价？结束报价后将不再接收针对该询盘的报价。': '',
    '暂无投标邀请': '',
    '确认删除该投标？': '',
    '产品收藏列表': '',
    '取消全选': '',
    '关注的供应商': '',
    '暂无收藏产品': '',
    '请选择产品': '',
    '确定取消关注吗?': '',
    '暂无收藏供应商': '',
    '待审核': '',
    '采购中': '',
    '已完成': '',
    '已拒绝': '',
    '请输入申请单号/项目名称': '',
    '申请单号': '',
    '项目名称': '',
    '申请时间': '',
    '查看订单': '',
    '暂无采购申请数据': '',
    '确定删除该条采购申请?': '',
    '拒绝理由': '',
    '申请单详情': '',
    '设计师信息': '',
    '申请单号：': '',
    '设计师名称：': '',
    '申请时间：': '',
    '用户名：': '',
    '项目名称： --': '',
    '项目信息': '',
    '订单信息': '',
    '名称：': '',
    '规格：': '',
    '订货编码': '',
    '确定删除足迹吗?': '',
    '认证设计师': '',
    '个人用户': '',
    '查看全部订单': '',
    '待付款': '',
    '待发货': '',
    '确定要清空吗?': '',
    '待收货': '',
    '部分发货': '',
    '退款售后': '',
    '订单状态': '',
    '订单号': '',
    '采购单号': '',
    '这里空空如也，快去挑选合适的产品吧~': '',
    '认证企业': '',
    '进行中的订单': '',
    '暂无商品~': '',
    '常购产品': '',
    '暂无常购产品～': '',
    '加载中....': '',
    '供应商关注': '',
    '暂无关注供应商～': '',
    '暂无足迹～': '',
    '暂无收藏产品～': '',
    '从个人中心进入 供应商': '',
    '用户头像：': '',
    '供应商 : ': '',
    '仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。': '',
    '昵称：': '',
    '真实姓名：': '',
    '性别：': '',
    '女': '',
    '保密': '',
    '男': '',
    '生日：': '',
    '年': '',
    '月': '',
    '所属企业：': '',
    '日': '',
    '上传图片成功': '',
    '图像应小于4MB': '',
    '上传图片失败': '',
    '请输入正确昵称': '',
    '真实姓名包含特殊字符，请重新输入': '',
    '产品名称：': '',
    '请输入物料编码': '',
    '请输入订货编码': '',
    '模版导入': '',
    '供应商：': '',
    '新增物料编码': '',
    '快速添加': '',
    '规格': '',
    '启用状态': '',
    '确定删除此编码？': '',
    '操作提示': '',
    '导入物料编码': '',
    '请先下载导入模版，并按照批注中的要求填写数据，未按要求填写将会导致导入失败': '',
    '请选择.xls文件，每次只能导入一个文件，建议每次导入不超过': '',
    '下载导入模版': '',
    '条产品数据': '',
    '完成': '',
    '上传文件': '',
    '下载标准模版': '',
    '下一步': '',
    '点击上传或者拖拽文件到该区域即可': '',
    '您可以': '',
    '下载错误表格': '',
    '查看错误原因，修改后重新上传。': '',
    '上传失败！': '',
    '导入成功！': '',
    '您可以关闭该弹框查看已导入的物料编码，或是继续导入。': '',
    '继续导入': '',
    '产品信息：': '',
    '是否启用：': '',
    '请输入产品的订货编码': '',
    '货品不存在': '',
    '添加物料编码': '',
    '由购物车添加': '',
    '由已购产品添加': '',
    '由收藏产品添加': '',
    '暂无产品': '',
    '请填订货编码': '',
    '请先获取产品信息': '',
    '请填物料编码': '',
    '上传的文件格式不正确': '',
    '请选择产品!': '',
    '编辑物料编码': '',
    '标准模板': '',
    '错误表格': '',
    '选中产品的物料编码不能为空!': '',
    '收入': '',
    '支出': '',
    '日期': '',
    '收入/支出': '',
    '详细说明': '',
    '暂无积分记录~': '',
    '绑定手机：': '',
    '若手机丢失或停用，请及时更换': '',
    '尚未绑定手机号': '',
    '手机号码': '',
    '修改号码': '',
    '绑定号码': '',
    '电子邮箱': '',
    '绑定邮箱：': '',
    '尚未绑定邮箱': '',
    '修改邮箱': '',
    '若邮箱已停用，请及时更换': '',
    '绑定邮箱': '',
    '修改密码': '',
    '安全性高的密码可以保障您的账号安全，建议密码为6～20位,由英文、数字或符号的组合': '',
    '在使用信用支付时需输入支付密码以保证您的资金安全': '',
    '修改电子邮箱': '',
    '绑定电子邮箱': '',
    '设置密码': '',
    '请输入邮箱验证码': '',
    '当前邮箱': '',
    '为了保障您的账号安全，变更重要信息需进行身份验证。': '',
    '请输入新邮箱': '',
    '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。': '',
    '变更过程中有任何疑问请联系在线客服解决。': '',
    '温馨提示': '',
    '请先绑定手机号，再进行登陆密码操作!': '',
    '当前手机号': '',
    '修改': '',
    '请输入原密码': '',
    '请再次输入密码': '',
    '请输入新密码': '',
    '复杂的密码可使账号更安全且建议定期更换密码。': '',
    '请输入旧密码': '',
    '请先绑定手机号，再进行操作!': '',
    '请再次输入新密码': '',
    '请输入一致的新密码': '',
    '请再次输入支付密码': '',
    '请输入一致的密码': '',
    '请先绑定手机号，再进行支付密码操作!': '',
    '请输入原支付密码': '',
    '设置支付密码': '',
    '请再次输入新支付密码': '',
    '修改支付密码': '',
    '请输入新支付密码': '',
    '请输入旧支付密码': '',
    '请输入一致的新支付密码': '',
    '重置支付密码': '',
    '请输入新手机号': '',
    '合同申请': '',
    '（纸质合同的申请需要由买家下载纸质合同并签署后邮寄给相应的供应商）': '',
    '请输入一致的支付密码': '',
    '选择订单': '',
    '信息确认': '',
    '请先绑定手机号，再进行重置支付密码操作!': '',
    '合同申请成功': '',
    '请输入采购单号': '',
    '请输入订单号': '',
    '下单时间：': '',
    '请输入供应商': '',
    '请确认需申请合同的订单': '',
    '完成时间：': '',
    '个订单': '',
    '已选择以下': '',
    '暂无待申请合同': '',
    '请确认并填写以下信息': '',
    '下单时间': '',
    '订单金额': '',
    '需方信息：': '',
    '自定义合同号：': '',
    '请输入自定义合同号': '',
    '合同备注：': '',
    '请输入单位名称': '',
    '单位地址：': '',
    '请输入合同备注': '',
    '法定代理人：': '',
    '请输入单位地址': '',
    '单位名称：': '',
    '请输入法定代理人': '',
    '请输入委托代理人': '',
    '委托代理人：': '',
    '单位邮编：': '',
    '单位传真：': '',
    '请输入电话': '',
    '请输入单位邮编': '',
    '请输入开户银行名称': '',
    '请输入单位传真': '',
    '银行账号：': '',
    '税号：': '',
    '请输入开户银行账号': '',
    '请输入税号': '',
    '请输入开户身份证号': '',
    '身份证号：': '',
    '当前创建的为合同草稿，可下载预览；转为正式合同后具有法律效力，落款章有效。': '',
    '您的合同（草稿）创建成功': '',
    '合同号': '',
    '开票方式：': '',
    '预览': '',
    '转为正式': '',
    '上一步': '',
    '申请合同': '',
    '申请数量：': '',
    '申请数量': '',
    '请输入申请数量': '',
    '转为正式合同': '',
    '转为正式电子合同': '',
    '转为正式纸质合同': '',
    '申请数量最多为': '',
    '请输入正确的数量': '',
    '请设置选中订单的申请数量': '',
    '请输入正确的电话': '',
    '请输入正确的单位传真': '',
    '请输入正确的银行账号': '',
    '请输入正确的身份证号': '',
    '请输入正确的单位邮编': '',
    '请在合同列表页查看': '',
    '请输入正确的税号': '',
    '请选择合同类型': '',
    '获取认证信息失败': '',
    '个人实名认证': '',
    '未认证': '',
    '企业实名认证': '',
    '去认证': '',
    '完善身份信息': '',
    '已认证': '',
    '填写验证码': '',
    '完成认证': '',
    '请输入身份证号': '',
    '请输入身份证上姓名': '',
    '请输入用以上身份证号办理的手机号': '',
    '请勾选后再进行下一步': '',
    '《数字证书使用协议》': '',
    '我已阅读并确认': '',
    's后可重新发送': '',
    '认证成功!': '',
    '已成功为您发放CA 证书。仅在您同意的情况下，您的证书方可被调用签署相关的电子合同等法律文件。': '',
    '验证码：': '',
    's后自动返回至合同管理页': '',
    '立即返回': '',
    '请输入法定代表人名称': '',
    '法定代表人名称：': '',
    '法定代表人身份证号：': '',
    '请输入法定代表人身份证号': '',
    '我是法人': '',
    '认证方式：': '',
    '法定代表人手机号：': '',
    '我是经办人': '',
    '请输入法定代表人手机号': '',
    '经办人身份证号：': '',
    '经办人名称：': '',
    '请输入经办人名称': '',
    '请输入经办人手机号': '',
    '请输入经办人身份证号': '',
    '经办人手机号：': '',
    '授权书：': '',
    '请上传文件': '',
    '上传图片': '',
    '实名认证': '',
    '请输入真实姓名': '',
    '请输入正确的真实姓名': '',
    '请输入正确的法定代表人身份证号': '',
    '请输入正确的经办人身份证号': '',
    '暂无合同详情': '',
    '请上传授权书': '',
    '合同管理': '',
    '个人: ': '',
    '认证信息': '',
    '签名': '',
    '企业: ': '',
    '商户: ': '',
    '未设置': '',
    '合同号：': '',
    '请输入合同号': '',
    '创建时间：': '',
    '置': '',
    '搜': '',
    '索': '',
    '合同金额': '',
    '重': '',
    '包含订单': '',
    '创建时间': '',
    '合同形式': '',
    '查看': '',
    '备注': '',
    '暂无申请记录': '',
    '作废': '',
    '编辑备注': '',
    '请输入备注': '',
    '因您尚未完成实名认证，目前仅支持申请纸质合同。': '',
    '将调用您的数字证书进行签署，您正在安全签约环境中，请放心签署。': '',
    '即将向您': '',
    '的手机号码发送确认签署验证码': '',
    '请输入手机验证码': '',
    '请尽快输入验证码完成签署': '',
    '签署验证': '',
    's后重新发送': '',
    '待供方处理': '',
    '待选择合同类型': '',
    '废弃': '',
    '正式': '',
    '请输入备注信息': '',
    '确定作废该合同?': '',
    '验证手机号获取错误': '',
    '待结算': '',
    '信用账单号/采购单号/订单号': '',
    '已结算': '',
    '账单生成时间': '',
    '最后结算日': '',
    '已逾期': '',
    '账单金额': '',
    '信用账单号：': '',
    '应结算金额': '',
    '即将逾期': '',
    '汇款时间：': '',
    '确认时间：': '',
    '结算': '',
    '暂无信用账单数据': '',
    '取消合并': '',
    '暂无订单～': '',
    '结算状态': '',
    '您尚未认证成为企业用户，认证后即可享受信用支付服务。': '',
    '待结算金额：': '',
    '注意事项': '',
    '（转账必读）': '',
    '已完成汇款': '',
    '上传凭证失败': '',
    '请上传': '',
    '上传凭证成功': '',
    '汇款信息的汇款凭证': '',
    '付款成功': '',
    '复制成功': '',
    '您的浏览器不支持快捷复制': '',
    '拒绝采购': '',
    '编辑成功': '',
    '拒绝理由：': '',
    '请输入拒绝理由': '',
    '已超过限定数量': '',
    '采购申请单': '',
    '申请单号/设计师名称/用户名/手机号': '',
    '设计师名称': '',
    '用户名': '',
    '消费记录': '',
    '手机号': '',
    '可申请企业': '',
    '申请记录': '',
    '授信额度：': '',
    '剩余可用额度：': '',
    '审核中': '',
    '账期：': '',
    '申请授信': '',
    '申请失败,您可以查看申请记录或重新申请。': '',
    '授信额度': '',
    '授信可用额度': '',
    '暂无可申请企业': '',
    '暂无消费记录': '',
    '授权信息': '',
    '申请企业': '',
    '审核状态': '',
    '授信企业': '',
    '授信额度：¥': '',
    '申请已提交': '',
    '重新审核': '',
    '可开票金额': '',
    '更新时间': '',
    '可开票金额=总计消费可开票金额-历史已开票金额': '',
    '发票抬头': '',
    '去开票': '',
    '暂无发票': '',
    '管理发票抬头': '',
    '寄送信息': '',
    '暂无信息': '',
    '电子邮箱:': '',
    '管理寄送信息': '',
    '开票记录': '',
    '发票号：': '',
    '发票状态：': '',
    '请输入订单编号': '',
    '请输入发票号': '',
    '发票类型': '',
    '开票主体': '',
    '发票号': '',
    '暂无开票记录': '',
    '发票备注': '',
    '发票性质': '',
    '发票状态': '',
    '发票金额': '',
    '纸质': '',
    '电子': '',
    '查看物流': '',
    '编辑发票备注': '',
    '请输入发票备注': '',
    '物流信息': '',
    '发票备注：': '',
    '联系电话': '',
    '联系人': '',
    '承运人': '',
    '运单号': '',
    '已开票': '',
    '发票备注不能为空': '',
    '已作废': '',
    '开票中': '',
    '确定作废？': '',
    '选择待开票数据': '',
    '编辑失败': '',
    '开票': '',
    '确定发票信息和地址': '',
    '订单编号：': '',
    '可开发票金额：': '',
    '支付时间：': '',
    '支付时间': '',
    '暂无待开票数据': '',
    '订单编号': '',
    '开票金额：': '',
    '开票主体：': '',
    '增值税普通发票（电子）': '',
    '增值税普通发票（纸质）': '',
    '请选择发票抬头': '',
    '增值税专用发票（纸质）': '',
    '电子邮箱：': '',
    '寄送信息：': '',
    '选择地址': '',
    '选择邮箱': '',
    '该备注信息会展示在发票上，如有需要请录入，否则无需录入任何信息。': '',
    '发票抬头：': '',
    '建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到。': '',
    '默认邮箱': '',
    '编辑申请数量': '',
    '申请数量最大为': '',
    '寄送信息管理': '',
    '寄送地址': '',
    '暂无地址信息': '',
    '地址': '',
    '收件人姓名': '',
    '设为默认': '',
    '暂无地址，请': '',
    '暂无数据，请': '',
    '新增邮箱': '',
    '添加地址': '',
    '抬头类型：': '',
    '确定删除该邮箱?': '',
    '请输入个人真实姓名': '',
    '公司': '',
    '请输入发票抬头': '',
    '统一社会信用代码：': '',
    '请输入统一社会信用代码': '',
    '请输入注册场所地址': '',
    '注册场所地址：': '',
    '请输入注册固定电话': '',
    '注册固定电话：': '',
    '请输入银行账号': '',
    '默认发票：': '',
    '否': '',
    '是': '',
    '添加发票抬头': '',
    '请输入发票抬头!': '',
    '请输入正确的发票抬头!': '',
    '请输入注册固定电话!': '',
    '请输入注册场所地址!': '',
    '请输入统一社会信用代码!': '',
    '请输入正确的统一社会信用代码!': '',
    '请输入正确的注册固定电话!': '',
    '请输入开户银行名称!': '',
    '请输入银行账号!': '',
    '发票抬头管理': '',
    '新增发票抬头': '',
    '类型': '',
    '社会统一信用代码': '',
    '编辑发票抬头': '',
    '确定删除该发票抬头?': '',
    '发票信息': '',
    '发票详情': '',
    '发票性质：': '',
    '备注信息：': '',
    '发票金额：': '',
    '收票人：': '',
    '收票信息': '',
    '暂无订单信息': '',
    '发票详情数据有误': '',
    '邮箱：': '',
    '卖方合约订单': '',
    '全部状态': '',
    '买方合约订单': '',
    '待确认': '',
    '已达成': '',
    '已失效': '',
    '合约单号': '',
    '卖方': '',
    '买方': '',
    '合约产品': '',
    '合约金额(元)': '',
    '对应询盘': '',
    '确认删除该询盘？删除后数据不可恢复。': '',
    '去确认': '',
    '报价结束': '',
    '待报价': '',
    '合约中': '',
    '已关闭': '',
    '审核拒绝': '',
    '开始时间': '',
    '结束时间': '',
    '预计采购金额(元)': '',
    '发布时间': '',
    '份报价': '',
    '已获报价': '',
    '状态:': '',
    '结束报价': '',
    '报价总金额(元)': '',
    '查看合约': '',
    '待洽谈': '',
    '待发起合约': '',
    '我已知悉？': '',
    '绑定手机号': '',
    '去注册': '',
    '绑定': '',
    '继续绑定：将解除与账号': '',
    '该手机号已被绑定，请更换其他手机号': '',
    '的绑定关系': '',
    '更新信息：授权信息将绑定到账号': '',
    '上': '',
    '继续绑定': '',
    '更新信息': '',
    '想起密码？': '',
    '去登录': '',
    '找回密码': '',
    '请输入6～20位英文、数字、符号': '',
    '还没注册？': '',
    '注册账号': '',
    '已有账号？': '',
    '请输入图形验证码': '',
    '我同意': '',
    '《用户注册协议》': '',
    '已有账号，去登录': '',
    '《隐私政策》': '',
    '请同意用户注册协议及隐私政策': '',
    '总金额': '',
    '可用余额': '',
    '余额明细': '',
    '充值明细': '',
    '充值': '',
    '交易日期': '',
    '变动原因': '',
    '暂无余额明细~': '',
    '充值金额': '',
    '我的优惠卷': '',
    '暂无充值明细~': '',
    '未使用': '',
    '1.填写充值金额': '',
    '已使用': '',
    '2.在线支付': '',
    '已过期': '',
    '填写充值金额': '',
    '充值账户': '',
    '3.充值完成': '',
    '请输入金额': '',
    '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；': '',
    '2.充值金额输入值必须是不小于1且不大于5000的正整数；': '',
    '3.充值完成后，您可至会员中心查看充值记录。': '',
    '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账': '',
    '您正在充值余额，请尽快支付': '',
    '请使用手机微信扫描下方二维码进行支付': '',
    '应付金额': '',
    '选择充值方式': '',
    '立即充值': '',
    '微信支付支付': '',
    '如二维码过期，': '',
    '使用微信扫码支付': '',
    '请输入充值金额': '',
    '超过充值金额上限': '',
    '重新获取二维码。': '',
    '充值成功,2s后自动跳转支付页面': '',
    '充值成功,2s后自动跳转充值列表': '',
    '申请类型': '',
    '退款单号': '',
    '退款金额': '',
    '仅退款': '',
    '退货退款': '',
    '添加/编辑': '',
    '发货': '',
    '（同订单商品可批量申请）': '',
    '添加和编辑': '',
    '退款金额不可修改，最多': '',
    '同订单产品可批量申请': '',
    '修改金额': '',
    '不含运费': '',
    '含运费': '',
    '退款金额可修改，最多': '',
    '请输入退款金额': '',
    '申请件数': '',
    '请输入申请件数': '',
    '货物状态': '',
    '退款原因': '',
    '请选择退款原因': '',
    '请输入问题描述(选填)': '',
    '问题描述': '',
    '上传凭证': '',
    '张图片': '',
    '总共上传': '',
    '请输入接收退款的银行账号': '',
    '开户行：': '',
    '退款账户：': '',
    '退款账户需和付款账户保持一致，否则无法退款。': '',
    '批量售后产品选择': '',
    '请输入接收退款银行账号的开户行': '',
    '描述文字': '',
    '已收到货': '',
    '未收到货': '',
    '退款退货': '',
    '退款金额不可为0或者负值！': '',
    '不可超出最大申请数量': '',
    '不可超过最大退款金额！': '',
    '最多上传5张！': '',
    '类型必须为图片': '',
    '确认收货': '',
    '立即支付': '',
    '取消订单': '',
    '修改地址': '',
    '删除订单': '',
    '送货方式': '',
    '交易关闭': '',
    '支付订单': '',
    '商家发货': '',
    '暂无物流信息': '',
    '快递': '',
    '无需物流': '',
    '不需要发票': '',
    '交易流水号': '',
    '订单取消备注': '',
    '订单备注': '',
    '卖家': '',
    '满优惠': '',
    '运费金额': '',
    '店铺优惠券': '',
    '积分抵扣': '',
    '实际金额': '',
    '取消该订单定金不予退还,确定取消?': '',
    '请选择取消理由': '',
    '取消订单理由': '',
    '取消订单成功': '',
    '确认删除该订单?': '',
    '确认收货?': '',
    '删除订单成功': '',
    '切换地址成功': '',
    '评价订单': '',
    '确认收货成功': '',
    '订单：': '',
    '服务': '',
    '综合': '',
    '物流': '',
    '描述相符': '',
    '发货速度': '',
    '产品评分': '',
    '服务态度': '',
    '评价晒单': '',
    '发表评价': '',
    '晒图': '',
    '交易评价/晒单': '',
    '请输入内容': '',
    '待评订单': '',
    '您的评价可以帮助他人更真实的了解产品': '',
    '从多个角度评价产品，可以帮助更多想买的人': '',
    '评价小贴士': '',
    '金额': '',
    '支付金额': '',
    '供应商审核': '',
    '交易取消': '',
    '订单结束': '',
    '供应商发货': '',
    '采购付款': '',
    '供应商名称：': '',
    '交易完成': '',
    '支付方式：': '',
    '查看账单': '',
    '信用账单信息': '',
    '结算金额：': '',
    '结算单号：': '',
    '最后结算日：': '',
    '结算状态：': '',
    '期望交货时间：': '',
    '等待供应商审核': '',
    '等待供应商报价': '',
    '供应商审核通过，等待采购付款': '',
    '等待供应商发货': '',
    '供应商已分批发货': '',
    '等待买家确认收货': '',
    '供应商审核拒绝，订单结束': '',
    '订单已取消': '',
    '完成交易': '',
    '取消原因：': '',
    '批次': '',
    '物料信息': '',
    '已申请合同': '',
    '总价': '',
    '订单详情数据错误': '',
    '产品名称/订货编码/采购单号/订单号': '',
    '采购单号: ': '',
    '暂无详细物流信息': '',
    '用户发货': '',
    '从订单列表进入 供应商': '',
    '服务类型': '',
    '物流公司': '',
    '请输入物流单号': '',
    '物流单号': '',
    '退货地址：': '',
    '请输入正确的物流单号！': '',
    '请输入物流单号！': '',
    '请选择物流公司!': '',
    '起订量: ': '',
    '项目详情': '',
    '暂无产品数据': '',
    '导出已选产品': '',
    '起订量:': '',
    '删除已选产品': '',
    '清空无效产品': '',
    '立即购买': '',
    '请选择要导出的产品': '',
    '导出失败': '',
    '导出成功': '',
    '确定删除选中产品?': '',
    '确定清空无效产品?': '',
    '未开始': '',
    '进行中': '',
    '项目周期': '',
    '暂无我的项目数据': '',
    '项目描述': '',
    '项目状态': '',
    '删除成功': '',
    '确定删除项目?': '',
    '询价订单': '',
    '买家申请退款': '',
    '采购单号:': '',
    '退款退货单号': '',
    '买家申请退款退货': '',
    '供应商拒绝退款': '',
    '提交申请': '',
    '供应商确认退款': '',
    '退款凭证': '',
    '操作时间：': '',
    '平台审核备注': '',
    '近半年常购': '',
    '近三个月常购': '',
    '产品名称/订货编码': '',
    '产品名称/订货编码/物料编码': '',
    '今年常购': '',
    '暂无常购清单数据': '',
    '订单完成': '',
    '已取消': '',
    '样品订单详情进入 供应商': '',
    '产品名称/订货编码/订单号': '',
    '您尚未认证成为企业用户，认证后可创建子账号并使用子账号申请样品。': '',
    '从订单列表进入': '',
    '积分订单详情': '',
    '我的': '',
    '无': '',
    '产品合计': '',
    '积分兑换订单': '',
    '待支付': '',
    '全部订单': '',
    '兑换时间：': '',
    '搜索订单': '',
    '产品名称/订单号': '',
    '兑换单号: ': '',
    '请输入手机号码': '',
    '创建子账号': '',
    '密码：': '',
    '确认密码：': '',
    '请设置6-20位字母、数字或符号组成的密码': '',
    '请输入账号名称': '',
    '确认密码不一致': '',
    '确认创建': '',
    '启用状态:': '',
    '请选择启用状态': '',
    '批量删除': '',
    '名称': '',
    '名称/用户名/手机号': '',
    '开启': '',
    '禁用': '',
    '请选择要删除的子账号': '',
    '确定删除该子账号？删除后账号不可继续使用。': '',
    '启用': '',
    '最多可创建10个子账号': '',
    '新密码：': '',
    '批量标为已读': '',
    '查看详情 >': '',
    '全部标为已读': '',
    '确定删除这条消息吗？': '',
    '系统消息': '',
    '暂时没有消息！': '',
    '发票通知': '',
    '消息提醒': '',
    '产品消息': '',
    '认证通知': '',
    '资产消息': '',
    '订单消息': '',
    '预约提醒': '',
    '售后消息': '',
    '接收': '',
    '请先选择要操作的消息！': '',
    '确定删除这些消息吗': '',
    '不接收': '',
    '联系方式：': '',
    '我要开发票': '',
    '实付款：': '',
    '给商家留言': '',
    '当前积分数：': '',
    '积分选择': '',
    '重新选择': '',
    '发票历史信息选择：': '',
    '发票内容：': '',
    '积分不足': '',
    '新增发票': '',
    '发票内容将显示产品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额': '',
    '请在此填写发票抬头': '',
    '请在此填写部门名称': '',
    '请在此填写纳税人识别号': '',
    '普通发票': '',
    '请输入注册地址': '',
    '请输入注册电话': '',
    '请输入开户银行': '',
    '请输入收票人姓名': '',
    '请输入银行账户': '',
    '请输入收票人电话': '',
    '请输入收票人地址': '',
    '收票邮箱：': '',
    '请输入收票邮箱': '',
    '请填写注册地址': '',
    '设为默认发票': '',
    '新增成功': '',
    '订单提交成功，请您尽快付款！ 订单号：': '',
    '暂不使用': '',
    '请您在提交订单后': '',
    '完成支付，否则订单会自动取消': '',
    '小时内': '',
    '展开详情': '',
    '收起详情': '',
    '可用余额：': '',
    '元）余额不足？': '',
    '元，目前需要在线支付：': '',
    '使用余额支付': '',
    '未设置支付密码，马上去设置': '',
    '马上充值': '',
    '忘记密码?': '',
    '选择其它支付方式': '',
    '暂无可用的支付方式，平台正在紧急处理中～': '',
    '微信支付': '',
    '自营': '',
    '原价': '',
    '已兑换': '',
    '立即兑换': '',
    '热门礼品': '',
    '人气礼品': '',
    '不能输入非0数字!': '',
    '下单': '',
    ' 点这里': '',
    '供应商评分': '',
    '综合评分': '',
    '服务承诺': '',
    '客服电话': '',
    '正品保障': '',
    '请输入关键词': '',
    '供应商首页': '',
    '本店全部分类': '',
    '搜本店': '',
    '所有产品': '',
    '分类': '',
    '请输入...': '',
    '积分商城首页暂未装修': '',
    '暂无产品～': '',
    '即将开始': '',
    '已开抢': '',
    '设置提醒': '',
    '模块未开启': '',
    '操作方式：': '',
    '今日无秒杀': '',
    '模板导入': '',
    '手动添加': '',
    '添加': '',
    '查询': '',
    '或重新上传。': '',
    '最低起购': '',
    '导入失败！': '',
    '下载标准模板，按照模板要求填写数据。': '',
    '暂无匹配产品': '',
    '请输入数据': '',
    '匹配成功': '',
    '选型标准模板': '',
    '数量在1~99999区间': '',
    '超出购买限制': '',
    '历史记录': '',
    '详情': '',
    '商务审核通过': '',
    '商务确认': '',
    '发起申请': '',
    '拒绝原因：': '',
    '客服处理': '',
    '关闭理由：': '',
    '需求编号：': '',
    '提交时间：': '',
    '商务：': '',
    '客服：': '',
    '处理记录': '',
    '时间：': '',
    '需求描述：': '',
    '需求信息': '',
    '原始文件：': '',
    '选型清单': '',
    '下载原始文件': '',
    '打开图片': '',
    '预览文件': '',
    '反馈结果：': '',
    '型号转换反馈文件': '',
    '下载反馈文件': '',
    '反馈清单': '',
    '反馈备注：': '',
    '推荐产品': '',
    '需求产品': '',
    '请输入非0正整数': '',
    '片式合金箔固定电阻器': '',
    '处理中': '',
    '提交编码': '',
    '取消申请': '',
    '提交时间': '',
    '设计师需求描述，上传清单': '',
    '是否确认该条取消申请？取消后您可重新发起申请': '',
    '是否确认该条删除申请记录？': '',
    '供应商根据描述优化选型': '',
    '设计师下载清单，完成型号转换': '',
    '请输入需求描述，以便更好的辅助您进行选型': '',
    '将文件拖入框内，或': '',
    '支持上传.xls文件和JPEG、PNG格式的图片': '',
    '点击上传': '',
    '  联系电话: ': '',
    '国产替代产品': '',
    '可替代产品': '',
    '搜索历史：': '',
    '该系列已加入对比': '',
    '请选择数量': '',
    '供应商客服处理': '',
    '热门搜索：': '',
    '设计师描述需求，上传申请资料': '',
    '设计师查看样品申请单': '',
    '新增收货地址': '',
    '暂无收货地址': '',
    '请输入您需要申请产品的名称、规格型号、数量等信息': '',
    '申请资料：': '',
    '查看订单详情': '',
    '申请信息': '',
    '申请描述：': '',
    '申请清单': '',
    '下载申请文件': '',
    '是否确认取消该条申请？取消后您可重新发起申请': '',
    '是否确认删除该条申请记录？': '',
    '发送链接': '',
    '已读': '',
    '未读': '',
    '常见问题': '',
    '暂未有常见问题~': '',
    '按Ctrl+Enter发送': '',
    '按Enter发送': '',
    '发送': '',
    '暂无消息': '',
    '请选择图片类型文件': '',
    '从对话列表进入 供应商': '',
    '在线': '',
    '账号已登出': '',
    '是否要发送剪切板的图片?': '',
    '离线': '',
    '供应商产品': '',
    '平台产品': '',
    '暂无数据～': '',
    '暂无供应商产品～': '',
    '订单号/采购单号/产品名称': '',
    '确定关闭该对话吗？': '',
    '搜索最近联系人': '',
    '[视频]': '',
    '[文件]': '',
    '[图片]': '',
    '[产品]': '',
    '[订单]': '',
    '展开': '',
    '分类：': '',
    '人气': '',
    '销量': '',
    '件相关产品': '',
    '成交量': '',
    '企业介绍': '',
    '营业执照': '',
    '暂无企业介绍': '',
    '企业资质': '',
    '搜索新产品': '',
    '暂无企业动态': '',
    '企业动态': '',
    '动态详情': '',
    '店铺评分': '',
    '全部产品': '',
    '企业动态详情获取失败': '',
    '主营产品：': '',
    '新品专区': '',
    '低': '',
    '中': '',
    '高': '',
    '从供应商详情页进入 供应商': '',
    '关注成功': '',
    '取消关注成功': '',
    '全部资质': '',
    '关注失败': '',
    '取消关注失败': '',
    '已售': '',
    '本店暂无产品～': '',
    '友益': '',
    '晨晶电子': '',
    '友晟': '',
    '七星飞行': '',
    '七星微电子': '',
    '三水日明': '',
    '需登录才能操作': '',
    '请输入正确的手机号': '',
    '密码最多20位哦～': '',
    '密码最少6位哦～': '',
    '密码不可以有中文哦～': '',
    '密码中不可以有空格哦～': '',
    '只有设计师才可以使用该功能～': '',
    '请输入正确的短信验证码': '',
    '请输入6～20位的会员名': '',
    '请输入正确的邮箱验证码': '',
    '会员名须由中、英文、数字、"-"及"_"组成': '',
    '会员名不能全为数字': '',
    '请输入正确的图形验证码': '',
    '该功能在升级中～': '',

};
