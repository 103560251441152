<template>
	<router-view />
	<fixedTab></fixedTab>
	<loading v-show="isLoading && isShow"></loading>
</template>

<script>
import fixedTab from "@/components/fixedTab.vue";
import loading from "@/components/loading.vue";
import { ref, getCurrentInstance, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
export default {
	name: 'App',
	components: {
		fixedTab,
		loading
	},
	setup() {
		const { proxy } = getCurrentInstance()
		const router = useRouter();
		const isLoading = ref(false);
		const isShow = ref(true);
		const store = useStore()
		const configInfo = reactive({ data: {} })

		router.beforeEach((to) => {
			if (to.path == '/member/order/list') {
				isShow.value = false;
			} else {
				isShow.value = true;
			}
			isLoading.value = true;
		});

		router.afterEach(() => {
			isLoading.value = false;
		});

		// 获取系统配置信息
		const getSystemConfigInfo = () => {
			proxy.$get("v3/system/front/setting/getSettings", {
				names: "main_site_logo,main_user_center_logo,main_user_logon_bg,main_user_register_bg,pc_home_bottom_adv,main_user_forget_password_bg,basic_site_name," +
					"basic_site_icp,basic_site_copyright,basic_site_technical_support,mall_foot_QR_code_image1,mall_foot_QR_code_image2,basic_site_phone" +
					",main_admin_top_logo,platform_customer_service_name,platform_customer_service_logo"
			}).then(res => {
				if (res.state == 200) {
					configInfo.data = {
						main_site_logo: res.data[0],
						main_user_center_logo: res.data[1],
						main_user_logon_bg: res.data[2],
						main_user_register_bg: res.data[3],
						pc_home_bottom_adv: res.data[4],
						main_user_forget_password_bg: res.data[5],
						basic_site_name: res.data[6],
						basic_site_icp: res.data[7],
						basic_site_copyright: res.data[8],
						basic_site_technical_support: res.data[9],
						foot_qr_code1: res.data[10],
						foot_qr_code2: res.data[11],
						basic_site_phone: res.data[12],
						main_admin_top_logo: res.data[13],
						platform_customer_service_name: res.data[14],
						platform_customer_service_logo: res.data[15]
					}
					store.commit('updateConfigInfo', configInfo.data);
				}
			})
		};

		onMounted(() => {
			getSystemConfigInfo();
		})

		return { isLoading, isShow }
	}
}
</script>

<style lang="scss">
@import "./style/reset.scss";
@import "./style/base.scss";



//放大镜位置
.mouse-cover-canvas {
	position: absolute;
	top: 173px !important;
	left: 740px !important;
}

.sld_pagination {
	margin-top: 20px;
}

//顶部公共进度条颜色
#nprogress .bar {
	background: #2f6eff !important;
}


.need_inquire {
	/* width: 64px !important;
    height: 19px !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &.type1 {
      background-image: url('./assets/need_inquire1.png');

    }

    &.type2 {
      background-image: url('./assets/need_inquire2.png');

    } */
}
</style>