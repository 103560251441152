/*
 * @Author: cuihaopeng cuihaopeng@slodon.cn
 * @Date: 2023-06-09 14:25:31
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @LastEditTime: 2024-01-31 15:24:55
 * @FilePath: /pulizhenkong_pc/src/utils/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// /**
//  *  项目配置文件
//  */
// // export const apiUrl = 'https://b2b.55sld.com/';//接口请求地址
// export const apiUrl = 'https://dgplpc.slodon.cn/';//接口请求地址
// // export const mUrl = 'https://m-b2b.55sld.com/'//移动端网页地址
// export const mUrl = 'https://dgplmobile.slodon.cn/'//移动端网页地址
// // export const chatUrl = 'https://im-b2b.55sld.com';//客服地址
// export const chatUrl = 'https://dgplim.slodon.cn';//客服地址

// export const imgUrl = 'https://dgplimg.slodon.cn'


// export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
// export const title = 'B2B';//浏览器顶部title
// export const gdKey = '';//高德web-js key
// export const gdSecurityCode = ''//高德web-js 安全密钥
// export const statShowDebug = false;//是否开启统计的调试
module.exports = {
//   apiUrl:'https://dgplpc.slodon.cn/',
//   chatUrl:'https://im-b2b.55sld.com',
//   mUrl:'https://dgplmobile.slodon.cn/',
//   imgUrl:'https://dgplimg.slodon.cn/java/b2b/mobile/',//静态资源地址——线上开发
//   SearchImgUrl: 'https://dgplimg.slodon.cn',//系列图片前缀


  apiUrl: 'https://www.wdfik.com/',
  chatUrl: 'https://im.wdfik.com',
  mUrl:'https://www.wdfik.com/',
  imgUrl: 'https://image.wdfik.com/java/b2b/mobile/',//静态资源地址——线上开发
  SearchImgUrl: 'https://image.wdfik.com',//系列图片前缀
  title:'真空产品-沃德菲克网上商店',
  h5AppId: '',//微商城appid 
  uploadMaxSize: 20, //上传最大限制，以M为单位
  curLang: 'zh', //当前语言,zh:中文，若为其他语言，需要对应/static/language下面的文件名
  h5GdKey: '', //高德web-js key
  h5GdSecurityCode: '',//高德web-js安全密钥
  WxXcxGdKey: '', //高德小程序key
  statShowDebug: false, //是否开启统计的调试
};
// ** copyright *** slodon *** version-v3.7.2 *** date-2023-04-17 ***主版本v3.7.2


