/*
* 语言文件 zh：中文
* */
export const lang_zn = {
    //首页公共头部最顶部
    '全部分类': '全部分类',
    '会员信息': '会员信息',
    '查看合同': '查看合同',
    '我的订单': '我的订单',
    '发票管理': '发票管理',
    '授信申请': '授信申请',
    '编辑地址': '编辑地址',
    '添加收货地址': '添加收货地址',
    '请输入收货人姓名': '请输入收货人姓名',
    '电话号码': '电话号码',
    '请输入手机号': '请输入手机号',
    '请选择所在地区': '请选择所在地区',
    '所在地区': '所在地区',
    '设为默认地址': '设为默认地址',
    '请输入详细地址': '请输入详细地址',
    '详细地址': '详细地址',
    '收货人': '收货人',
    '请输入2~25个字符': '请输入2~25个字符',
    '确 定': '确 定',
    '取 消': '取 消',
    '请输入5~40个字符': '请输入5~40个字符',
    '添加至我的项目': '添加至我的项目',
    '请输入正确的电话号码': '请输入正确的电话号码',
    '请输入收货人': '请输入收货人',
    '暂无数据': '暂无数据',
    '确定': '确定',
    '创建项目': '创建项目',
    '行业知识': '行业知识',
    '取消': '取消',
    '更多': '更多',
    '新品速递': '新品速递',
    '供应商': '供应商',
    '快速下单': '快速下单',
    '立即认证': '立即认证',
    '国产替代': '国产替代',
    '购物车中还没有产品，赶紧选购吧': '购物车中还没有产品，赶紧选购吧',
    '删除': '删除',
    '去购物车': '去购物车',
    '件产品': '件产品',
    '共': '共',
    '删除成功！': '删除成功！',
    '最新加入的产品': '最新加入的产品',
    '搜索结果': '搜索结果',
    '件': '件',
    '设为特殊关注': '设为特殊关注',
    '取消特殊关注': '取消特殊关注',
    '取消关注': '取消关注',
    '进入供应商': '进入供应商',
    '折': '折',
    '热销推荐': '热销推荐',
    '随机券': '随机券',
    '本月上新': '本月上新',
    '领取': '领取',
    '已领取': '已领取',
    '使用规则': '使用规则',
    '已抢完': '已抢完',
    '立即领取': '立即领取',
    '已抢': '已抢',
    '已抢光': '已抢光',
    '领取成功': '领取成功',
    '立即使用': '立即使用',
    '配送至': '配送至',
    '请选择地址': '请选择地址',
    '常用地址': '常用地址',
    '足迹': '足迹',
    '购物车': '购物车',
    '运费': '运费',
    '其他地址': '其他地址',
    '免运费': '免运费',
    '型号转换': '型号转换',
    '平台客服': '平台客服',
    '发布询盘': '发布询盘',
    '样品申请': '样品申请',
    '返回顶部': '返回顶部',
    '企业认证': '企业认证',
    '提示': '提示',
    '已满足': '已满足',
    '我已知晓': '我已知晓',
    '未满足': '未满足',
    '暂不满足询价功能使用条件。满足以下条件后方可使用。': '暂不满足询价功能使用条件。满足以下条件后方可使用。',
    '版权所有': '版权所有',
    '合作伙伴：': '合作伙伴：',
    '提供技术支持': '提供技术支持',
    '包含分类': '包含分类',
    '工作时间：周一 ～ 周五': '工作时间：周一 ～ 周五',
    '暂无特性': '暂无特性',
    '已选参数：': '已选参数：',
    '产品系列': '产品系列',
    '暂无分类': '暂无分类',
    '个': '个',
    '清除筛选': '清除筛选',
    '阅读': '阅读',
    '加入对比': '加入对比',
    '编辑寄送地址': '编辑寄送地址',
    '添加寄送地址': '添加寄送地址',
    '请输入5~60个字符': '请输入5~60个字符',
    '请输入正确的详细地址': '请输入正确的详细地址',
    '添加邮箱': '添加邮箱',
    '请输入邮箱号': '请输入邮箱号',
    '编辑邮箱': '编辑邮箱',
    '邮箱': '邮箱',
    '设为默认邮箱': '设为默认邮箱',
    '请输入正确的邮箱': '请输入正确的邮箱',
    '请输入邮箱': '请输入邮箱',
    '向上滑动浏览更多数据': '向上滑动浏览更多数据',
    '数据加载完毕~': '数据加载完毕~',
    '数据加载中...': '数据加载中...',
    '手机登录': '手机登录',
    '请输入账号/手机号': '请输入账号/手机号',
    '请输入密码': '请输入密码',
    '账号登录': '账号登录',
    '请输入验证码': '请输入验证码',
    '点击刷新': '点击刷新',
    's后获取': 's后获取',
    '获取验证码': '获取验证码',
    '二维码已失效': '二维码已失效',
    '登录': '登录',
    '登录成功': '登录成功',
    '反应快': '反应快',
    '免输入': '免输入',
    '打开手机商城，扫描二维码': '打开手机商城，扫描二维码',
    '忘记密码': '忘记密码',
    '更安全': '更安全',
    '立即注册': '立即注册',
    '请输入短信验证码': '请输入短信验证码',
    '采购管理': '采购管理',
    '交易评价': '交易评价',
    '请输入正确的账号/手机号': '请输入正确的账号/手机号',
    '样品订单': '样品订单',
    '采购申请': '采购申请',
    '我的项目': '我的项目',
    '常购清单': '常购清单',
    '设计师申请单': '设计师申请单',
    '物料编码': '物料编码',
    '合约订单': '合约订单',
    '我的报价': '我的报价',
    '我的询价': '我的询价',
    '我的招标': '我的招标',
    '投标邀请': '投标邀请',
    '信用账单': '信用账单',
    '财务': '财务',
    '发票': '发票',
    '合同': '合同',
    '企业管理': '企业管理',
    '子账号管理': '子账号管理',
    '售后服务': '售后服务',
    '关注中心': '关注中心',
    '退货/退款': '退货/退款',
    '我的收藏': '我的收藏',
    '我的足迹': '我的足迹',
    '供应商店铺': '供应商店铺',
    '我的退款': '我的退款',
    '我的退货': '我的退货',
    '设置': '设置',
    '账户安全': '账户安全',
    '客户服务': '客户服务',
    '账户信息': '账户信息',
    '地址管理': '地址管理',
    '会员中心': '会员中心',
    '账号安全': '账号安全',
    '支付密码': '支付密码',
    '手机号管理': '手机号管理',
    '邮箱管理': '邮箱管理',
    '重置密码': '重置密码',
    '登录密码': '登录密码',
    '收货地址': '收货地址',
    '产品收藏': '产品收藏',
    '我的钱包': '我的钱包',
    '文章收藏': '文章收藏',
    '账户充值': '账户充值',
    '我的余额': '我的余额',
    '我的优惠券': '我的优惠券',
    '我的积分': '我的积分',
    '资产管理': '资产管理',
    '用户中心': '用户中心',
    '账户管理': '账户管理',
    '首页': '首页',
    '请输入产品名称/型号/物料编码': '请输入产品名称/型号/物料编码',
    '消息': '消息',
    '样品申请单': '样品申请单',
    '我的购物车': '我的购物车',
    '搜索': '搜索',
    '消息列表': '消息列表',
    '接收设置': '接收设置',
    '全部产品分类': '全部产品分类',
    '个人': '个人',
    '设计师': '设计师',
    '业务员': '业务员',
    '企业': '企业',
    '您好，欢迎来到': '您好，欢迎来到',
    '退出': '退出',
    '免费注册': '免费注册',
    '请登录': '请登录',
    '商城首页': '商城首页',
    '待付款订单': '待付款订单',
    '待收货订单': '待收货订单',
    '供应商收藏': '供应商收藏',
    '小程序': '小程序',
    '商务联系电话：': '商务联系电话：',
    '供应商联系电话：': '供应商联系电话：',
    '项目名称：': '项目名称：',
    '请输入项目名称': '请输入项目名称',
    '编辑项目': '编辑项目',
    '结束日期': '结束日期',
    '项目周期：': '项目周期：',
    '开始日期': '开始日期',
    '项目描述：': '项目描述：',
    '至': '至',
    '请输入项目描述': '请输入项目描述',
    '请输入正确的项目名称': '请输入正确的项目名称',
    '请输入1~20个字符': '请输入1~20个字符',
    '请选择项目周期': '请选择项目周期',
    '现货': '现货',
    '半成品': '半成品',
    '发货日：': '发货日：',
    '待产': '待产',
    '天': '天',
    '最低限购': '最低限购',
    '起订量': '起订量',
    '加入样品申请单': '加入样品申请单',
    '加入购物车': '加入购物车',
    '数量小于最小起订量': '数量小于最小起订量',
    '加入项目': '加入项目',
    '超过购买限制': '超过购买限制',
    '新品推荐': '新品推荐',
    '最新加入的样品': '最新加入的样品',
    '样品申请单中还没有产品，赶紧选购吧': '样品申请单中还没有产品，赶紧选购吧',
    '秒杀': '秒杀',
    '订货编码：': '订货编码：',
    '去样品申请单': '去样品申请单',
    '距离结束剩余：': '距离结束剩余：',
    '距离开始剩余：': '距离开始剩余：',
    '人购买': '人购买',
    '取消提醒': '取消提醒',
    '暂时没有数据～': '暂时没有数据～',
    '提醒我': '提醒我',
    '查看更多': '查看更多',
    '立即抢购': '立即抢购',
    '此处添加【403*高度不限】图片': '此处添加【403*高度不限】图片',
    '添加标题': '添加标题',
    '添加内容': '添加内容',
    '此处添加【186*340】图片': '此处添加【186*340】图片',
    '图片标题': '图片标题',
    '图片子标题': '图片子标题',
    '此处添加【172*106】图片': '此处添加【172*106】图片',
    '请上传500*80的图片': '请上传500*80的图片',
    '此处添加【187*120】图片': '此处添加【187*120】图片',
    '平台还未设置资讯': '平台还未设置资讯',
    '该场景还没有产品': '该场景还没有产品',
    '此处添加【360*560】图片': '此处添加【360*560】图片',
    '产品': '产品',
    '请输入文章标题': '请输入文章标题',
    '此处添加产品': '此处添加产品',
    '个产品': '个产品',
    '库存：': '库存：',
    '库存不足': '库存不足',
    '价格': '价格',
    '秒杀价': '秒杀价',
    '合计': '合计',
    '已选清单': '已选清单',
    '已选': '已选',
    '对比栏': '对比栏',
    '您还可以继续添加': '您还可以继续添加',
    '暂无可选清单': '暂无可选清单',
    '对比': '对比',
    '清空对比栏': '清空对比栏',
    '请添加对比条目': '请添加对比条目',
    '隐藏': '隐藏',
    '系列': '系列',
    '该': '该',
    '已加入对比': '已加入对比',
    '对比已达到4个': '对比已达到4个',
    '文章分类': '文章分类',
    '文章': '文章',
    '暂无文章数据': '暂无文章数据',
    '最新文章': '最新文章',
    '招标主题': '招标主题',
    '招标信息': '招标信息',
    '重置': '重置',
    '项目实施地区：': '项目实施地区：',
    '货物招标': '货物招标',
    '服务招标': '服务招标',
    '工程招标': '工程招标',
    '招标公告': '招标公告',
    '中标公告': '中标公告',
    '文档': '文档',
    '终止公告': '终止公告',
    '图片': '图片',
    '招标编号': '招标编号',
    '下载': '下载',
    '招标方式': '招标方式',
    '招标类型': '招标类型',
    '招标单位': '招标单位',
    '单位所在地址': '单位所在地址',
    '联系方式': '联系方式',
    '项目实施地区': '项目实施地区',
    '开标时间': '开标时间',
    '招标截止时间': '招标截止时间',
    '预算': '预算',
    '公告内容': '公告内容',
    '附件': '附件',
    '查看全部': '查看全部',
    ' 元': ' 元',
    '公开招标': '公开招标',
    '招标方式：': '招标方式：',
    '发布招标公告': '发布招标公告',
    '添加投标方': '添加投标方',
    '受邀投标方：': '受邀投标方：',
    '邀请招标': '邀请招标',
    '序号': '序号',
    '企业名称': '企业名称',
    '操作': '操作',
    '招标类型：': '招标类型：',
    '预算金额：': '预算金额：',
    '元': '元',
    '投标截止时间：': '投标截止时间：',
    '请选择': '请选择',
    '开标时间：': '开标时间：',
    '设置为投标截止时间': '设置为投标截止时间',
    '公告内容：': '公告内容：',
    '添加附件': '添加附件',
    '附件：': '附件：',
    '最多可添加5个文件。支持添加Word、PDF、Excel等文件格式。': '最多可添加5个文件。支持添加Word、PDF、Excel等文件格式。',
    '开标时间需大于投标截止时间': '开标时间需大于投标截止时间',
    '请优先设置投标截止时间': '请优先设置投标截止时间',
    '请填写': '请填写',
    '确认发布': '确认发布',
    '请填写预算金额': '请填写预算金额',
    '请选择投标截止时间': '请选择投标截止时间',
    '请选择项目实施地区': '请选择项目实施地区',
    '最多上传5个附件': '最多上传5个附件',
    '请选择开标时间': '请选择开标时间',
    '请填写公告内容': '请填写公告内容',
    '请选择受邀方': '请选择受邀方',
    '发布终止公告': '发布终止公告',
    '招标主题：': '招标主题：',
    '招标编号：': '招标编号：',
    '请填写公告标题': '请填写公告标题',
    '公告标题：': '公告标题：',
    '发布中标公告': '发布中标公告',
    '查看详情': '查看详情',
    '招标单位：': '招标单位：',
    '添加受邀投标方': '添加受邀投标方',
    '请输入企业名称': '请输入企业名称',
    '企业名称：': '企业名称：',
    '的招标邀请': '的招标邀请',
    '请选择投标方': '请选择投标方',
    '全选': '全选',
    '受邀投标方': '受邀投标方',
    '是否确认删除该条招标信息？': '是否确认删除该条招标信息？',
    '确认订单': '确认订单',
    '付款': '付款',
    '支付成功': '支付成功',
    '收货信息': '收货信息',
    '更换地址': '更换地址',
    '新建地址': '新建地址',
    '齐套发货': '齐套发货',
    '发货方式：': '发货方式：',
    '产品信息': '产品信息',
    '分批发货': '分批发货',
    '单价（元）': '单价（元）',
    '数量': '数量',
    '虚拟': '虚拟',
    '发货日': '发货日',
    '小计（元）': '小计（元）',
    '需询价': '需询价',
    '您的物料信息：': '您的物料信息：',
    '请输入产品名称': '请输入产品名称',
    '请输入产品自定义型号': '请输入产品自定义型号',
    '保存': '保存',
    '请': '请',
    '编辑': '编辑',
    '您的物料编号信息': '您的物料编号信息',
    '填写': '填写',
    '赠品': '赠品',
    '优惠券': '优惠券',
    '赠完为止': '赠完为止',
    '暂无可用优惠券': '暂无可用优惠券',
    '不使用优惠券': '不使用优惠券',
    '备注信息': '备注信息',
    '采购单号：': '采购单号：',
    '优惠': '优惠',
    '备注：': '备注：',
    '期望交货日期：': '期望交货日期：',
    '请备注贵公司的标准采购单号': '请备注贵公司的标准采购单号',
    '（此日期仅作参考。如产品发货日不满足期望交货日期则订单需供应商审核通过后再进行付款。）': '（此日期仅作参考。如产品发货日不满足期望交货日期则订单需供应商审核通过后再进行付款。）',
    '产品金额：': '产品金额：',
    '￥': '￥',
    '运费：': '运费：',
    '请输入备注信息，如没有特殊要求可不填写': '请输入备注信息，如没有特殊要求可不填写',
    '优惠：': '优惠：',
    '平台优惠券': '平台优惠券',
    '可用优惠券': '可用优惠券',
    '小计：': '小计：',
    '不可用优惠券': '不可用优惠券',
    '折扣券': '折扣券',
    '随机金额券': '随机金额券',
    '满减券': '满减券',
    '使用规则：': '使用规则：',
    '固定金额券': '固定金额券',
    '暂无优惠券': '暂无优惠券',
    '产品总额': '产品总额',
    '应付总额：': '应付总额：',
    '¥': '¥',
    '提交订单': '提交订单',
    '移除无货产品': '移除无货产品',
    '返回': '返回',
    '提交修改': '提交修改',
    '使用积分': '使用积分',
    '积分': '积分',
    '可用积分': '可用积分',
    '使用': '使用',
    '暂不使用积分': '暂不使用积分',
    '抵扣': '抵扣',
    '订单大于等于': '订单大于等于',
    '积分支付不超过订单金额的': '积分支付不超过订单金额的',
    '可用': '可用',
    '积分使用数量为': '积分使用数量为',
    '商品促销信息发生变化，请返回后重新下单': '商品促销信息发生变化，请返回后重新下单',
    '的整数倍': '的整数倍',
    '积分等于': '积分等于',
    '商品促销信息发生变化': '商品促销信息发生变化',
    '请选择发货方式': '请选择发货方式',
    '请新建收货地址': '请新建收货地址',
    '操作成功，待供应商审核': '操作成功，待供应商审核',
    '提交订单失败，请稍后重试': '提交订单失败，请稍后重试',
    ',2s后自动跳转订单列表': ',2s后自动跳转订单列表',
    '请输入正确的采购单号!': '请输入正确的采购单号!',
    '邮箱格式不正确，请重新输入！': '邮箱格式不正确，请重新输入！',
    '请输入': '请输入',
    '发货日:': '发货日:',
    '请填写正确的税号': '请填写正确的税号',
    '请填正确填写注册电话': '请填正确填写注册电话',
    '请填写收票邮箱': '请填写收票邮箱',
    '请填写发票抬头': '请填写发票抬头',
    '请填正确填写收票人电话': '请填正确填写收票人电话',
    '请填写部门名称': '请填写部门名称',
    '"请填写注册地址"': '"请填写注册地址"',
    '请填写纳税人税号': '请填写纳税人税号',
    '请填写开户银行': '请填写开户银行',
    '请填写注册电话': '请填写注册电话',
    '请填写收票人姓名': '请填写收票人姓名',
    '请填写收票人电话': '请填写收票人电话',
    '请填写银行账户': '请填写银行账户',
    '请填写收票人地址': '请填写收票人地址',
    '产品明细': '产品明细',
    '产品类别': '产品类别',
    '请输入正确的': '请输入正确的',
    '收货人：': '收货人：',
    '收货地址：': '收货地址：',
    '在线支付': '在线支付',
    '应付金额：': '应付金额：',
    '支付方式': '支付方式',
    '请输入银行卡号': '请输入银行卡号',
    '订单号：': '订单号：',
    '其他银行': '其他银行',
    '待支付金额：': '待支付金额：',
    '汇款信息': '汇款信息',
    '开户银行：': '开户银行：',
    '开户名称：': '开户名称：',
    '注意事项（转账必读）': '注意事项（转账必读）',
    '公司转账汇款时请将订单号': '公司转账汇款时请将订单号',
    '填写至汇款单中“用途/备注/摘要”等栏': '填写至汇款单中“用途/备注/摘要”等栏',
    '收款账户：': '收款账户：',
    '务必保证转账金额与订单金额一致，请勿多转/少转或分次转账。': '务必保证转账金额与订单金额一致，请勿多转/少转或分次转账。',
    '点击上传凭证': '点击上传凭证',
    '汇款凭证：': '汇款凭证：',
    '微信': '微信',
    '支付宝': '支付宝',
    '扫码完成支付': '扫码完成支付',
    '刷新': '刷新',
    '可用额度：': '可用额度：',
    '可支付': '可支付',
    '不可支付': '不可支付',
    '未设置支付密码': '未设置支付密码',
    '立即设置': '立即设置',
    '立即付款': '立即付款',
    '剩余付款时间：': '剩余付款时间：',
    '请选择支付方式': '请选择支付方式',
    '支付成功,2s后自动跳转订单列表': '支付成功,2s后自动跳转订单列表',
    '支付单已过期': '支付单已过期',
    '请输入支付密码': '请输入支付密码',
    '付款失败': '付款失败',
    '请上传凭证': '请上传凭证',
    '单价': '单价',
    '默认': '默认',
    '失效': '失效',
    '库存': '库存',
    '确定删除选中产品？': '确定删除选中产品？',
    '失效产品': '失效产品',
    '移入收藏夹': '移入收藏夹',
    '删除选中的产品': '删除选中的产品',
    '清空失效的产品': '清空失效的产品',
    '导出已选商品快速下单清单': '导出已选商品快速下单清单',
    '产品已选购': '产品已选购',
    '提交需求': '提交需求',
    '套': '套',
    '去结算': '去结算',
    '去首页': '去首页',
    '这里空空如也，快去首页逛逛吧～': '这里空空如也，快去首页逛逛吧～',
    '提交清单': '提交清单',
    '确定清空失效产品？': '确定清空失效产品？',
    '件!': '件!',
    '超过购买限制!': '超过购买限制!',
    '数量超出购买范围！': '数量超出购买范围！',
    '购买套数最少为1套': '购买套数最少为1套',
    '购买套数达到最大值': '购买套数达到最大值',
    '移入收藏夹成功！': '移入收藏夹成功！',
    '起订量为': '起订量为',
    '清空失效产品成功！': '清空失效产品成功！',
    '产品：': '产品：',
    '提交产品不能为空！': '提交产品不能为空！',
    '结算产品不能为空！': '结算产品不能为空！',
    '请选择要删除的产品': '请选择要删除的产品',
    '导出清单': '导出清单',
    '次': '次',
    '本月剩余申请金额：': '本月剩余申请金额：',
    '本月剩余可申请次数：': '本月剩余可申请次数：',
    '本月可申请总金额：': '本月可申请总金额：',
    '剩余': '剩余',
    '样品价值': '样品价值',
    '本月剩余额度': '本月剩余额度',
    '已选择': '已选择',
    '超额': '超额',
    '本月剩余可申请次数': '本月剩余可申请次数',
    '可申请次数不足': '可申请次数不足',
    '可申请额度不足': '可申请额度不足',
    '数量超出限制!': '数量超出限制!',
    '申请': '申请',
    '最低申请': '最低申请',
    '数量超出范围！': '数量超出范围！',
    '系列对比结果': '系列对比结果',
    '产品对比结果': '产品对比结果',
    '品牌中心': '品牌中心',
    '高亮不同项': '高亮不同项',
    '隐藏相同项': '隐藏相同项',
    '系列信息': '系列信息',
    '基本信息': '基本信息',
    '销售信息': '销售信息',
    '生产状态': '生产状态',
    '属性值': '属性值',
    '规格值': '规格值',
    '产品详情': '产品详情',
    '手机下单': '手机下单',
    '评价': '评价',
    '联系客服': '联系客服',
    '已关注': '已关注',
    '产品服务': '产品服务',
    '技术负责人': '技术负责人',
    '新浪': '新浪',
    '收藏': '收藏',
    '分享': '分享',
    '已收藏': '已收藏',
    '分享到微信': '分享到微信',
    '新品': '新品',
    '上市时间：': '上市时间：',
    '起订': '起订',
    '全部': '全部',
    '领取优惠券': '领取优惠券',
    '物料编码：': '物料编码：',
    '产品已下架，欢迎挑选其他产品~': '产品已下架，欢迎挑选其他产品~',
    '加入我的项目': '加入我的项目',
    '产品已下架': '产品已下架',
    '类似产品推荐': '类似产品推荐',
    '产品介绍': '产品介绍',
    '品牌': '品牌',
    '收起全部': '收起全部',
    '新品特性：': '新品特性：',
    '应用领域：': '应用领域：',
    '产品规格书：': '产品规格书：',
    '规格型号': '规格型号',
    '可替代型号：': '可替代型号：',
    '常见问题：': '常见问题：',
    '暂无产品服务~': '暂无产品服务~',
    '该产品暂无详情~': '该产品暂无详情~',
    '评分': '评分',
    '好评率': '好评率',
    '有图': '有图',
    '好评': '好评',
    '中评': '中评',
    '差评': '差评',
    '商家回复:': '商家回复:',
    '有物料编码': '有物料编码',
    '该商品暂无评论~': '该商品暂无评论~',
    '产品名称': '产品名称',
    '清空': '清空',
    '新品名称/型号': '新品名称/型号',
    '购买数量': '购买数量',
    '描述': '描述',
    '应用领域': '应用领域',
    '产品分类': '产品分类',
    '该新品的系列Id不存在': '该新品的系列Id不存在',
    '暂无符合条件的产品': '暂无符合条件的产品',
    '详情介绍：': '详情介绍：',
    '参数描述': '参数描述',
    '为你推荐好券': '为你推荐好券',
    '上一条：': '上一条：',
    '精选': '精选',
    '我们正在努力装修中，敬请期待～': '我们正在努力装修中，敬请期待～',
    '关注': '关注',
    '热门推荐': '热门推荐',
    '行业知识首页': '行业知识首页',
    '下一条：': '下一条：',
    '接受合约时间还剩下': '接受合约时间还剩下',
    '合约详情': '合约详情',
    '分，到期未接受则该合约失效。': '分，到期未接受则该合约失效。',
    '小时': '小时',
    '发起时间：': '发起时间：',
    '合约单号：': '合约单号：',
    '手机号：': '手机号：',
    '所在地区：': '所在地区：',
    '详细地址：': '详细地址：',
    '产品及费用清单': '产品及费用清单',
    '合约状态': '合约状态',
    '产品图片': '产品图片',
    '未税单价(元)': '未税单价(元)',
    '单价(元)': '单价(元)',
    '税率': '税率',
    '收费项': '收费项',
    '小计': '小计',
    '报价(元)': '报价(元)',
    '未税报价(元)': '未税报价(元)',
    '合计金额：': '合计金额：',
    '含税：': '含税：',
    '优惠金额：': '优惠金额：',
    '合约金额：': '合约金额：',
    '合约接受截止时间：': '合约接受截止时间：',
    '卖方：': '卖方：',
    '交易合约信息': '交易合约信息',
    '买方：': '买方：',
    '最晚交付日期：': '最晚交付日期：',
    '结算方式：': '结算方式：',
    '配送方式：': '配送方式：',
    '其他约定': '其他约定',
    '发票类型：': '发票类型：',
    '其他约定：': '其他约定：',
    '确认接受该合约？/n请确认对合约信息无异议后再接受合约。': '确认接受该合约？/n请确认对合约信息无异议后再接受合约。',
    '接受合约': '接受合约',
    '我再看看': '我再看看',
    '增值税专用发票': '增值税专用发票',
    '询盘标题': '询盘标题',
    '增值税普通发票': '增值税普通发票',
    '商机金额': '商机金额',
    '无需发票': '无需发票',
    '最小值': '最小值',
    '状态': '状态',
    '最大值': '最大值',
    '收货地区': '收货地区',
    '未报价': '未报价',
    '已报价': '已报价',
    '暂无询价': '暂无询价',
    '报价信息': '报价信息',
    '规格/型号': '规格/型号',
    '产品报价': '产品报价',
    '编辑报价': '编辑报价',
    '发起报价': '发起报价',
    '小计(元)': '小计(元)',
    '其他费用': '其他费用',
    '含税': '含税',
    '添加产品': '添加产品',
    '添加收费项': '添加收费项',
    '报价(元）': '报价(元）',
    '合计：': '合计：',
    '未含税': '未含税',
    '交易信息': '交易信息',
    '定金发货': '定金发货',
    '未税价：': '未税价：',
    '全额付款': '全额付款',
    '分期付款': '分期付款',
    '其他': '其他',
    '发票要求：': '发票要求：',
    '联系人姓名：': '联系人姓名：',
    '联系人信息': '联系人信息',
    '联系人手机号：': '联系人手机号：',
    '其他说明': '其他说明',
    '指定配送方式：': '指定配送方式：',
    '买方自提': '买方自提',
    '预计到货：': '预计到货：',
    '达成合约后': '达成合约后',
    '供方承运': '供方承运',
    '其他说明：': '其他说明：',
    '可输入产品的详情情况介绍或其他被备注说明': '可输入产品的详情情况介绍或其他被备注说明',
    '确': '确',
    '请填写产品数量': '请填写产品数量',
    '认': '认',
    '请填写正确的产品单价': '请填写正确的产品单价',
    '请填写正确的税率': '请填写正确的税率',
    '请填写单位': '请填写单位',
    '请输入收费项': '请输入收费项',
    '请输入正确报价': '请输入正确报价',
    '请输入正确的税率': '请输入正确的税率',
    '请填写配送方式': '请填写配送方式',
    '请填写结算方式': '请填写结算方式',
    '请输入联系人姓名': '请输入联系人姓名',
    '格式错误，请输入正确的手机号码': '格式错误，请输入正确的手机号码',
    '请输入正确的合约天数': '请输入正确的合约天数',
    '截止时间：': '截止时间：',
    '已获得报价数': '已获得报价数',
    '等共': '等共',
    '联系人：': '联系人：',
    '商机金额：': '商机金额：',
    '电话：': '电话：',
    '确认接受该合约？\n请确认对合约信息无异议后再接受合约。': '确认接受该合约？\n请确认对合约信息无异议后再接受合约。',
    '修改报价': '修改报价',
    '去报价': '去报价',
    '询盘需求': '询盘需求',
    '询盘标题：': '询盘标题：',
    '产品清单': '产品清单',
    '期望单价(元)': '期望单价(元)',
    '添': '添',
    '期望收货日期': '期望收货日期',
    '加': '加',
    '报价截止时间': '报价截止时间',
    '详情描述：': '详情描述：',
    '参考文件：': '参考文件：',
    '请输入您对产品功能、规格、使用场景或其他方面的要求': '请输入您对产品功能、规格、使用场景或其他方面的要求',
    '（支持添加doc、docx、xls、xlsx、pdf、jpg、jpeg、png格式的文件）': '（支持添加doc、docx、xls、xlsx、pdf、jpg、jpeg、png格式的文件）',
    '确认': '确认',
    '发布': '发布',
    '最多上传三个附件': '最多上传三个附件',
    '请输入询盘标题': '请输入询盘标题',
    '请输入产品数量': '请输入产品数量',
    '产品数量必须为大于0的正数': '产品数量必须为大于0的正数',
    '期望单价必须为大于0的正数': '期望单价必须为大于0的正数',
    '产品数量必须正整数': '产品数量必须正整数',
    '请填写期望单价': '请填写期望单价',
    '期望单价的最大值不能小于最小值': '期望单价的最大值不能小于最小值',
    '请选择报价截止日期': '请选择报价截止日期',
    '请选择期望收货日期': '请选择期望收货日期',
    '请填写详细地址': '请填写详细地址',
    '请选择收货地址': '请选择收货地址',
    '询盘状态：': '询盘状态：',
    '报价截止时间：': '报价截止时间：',
    '询价详情': '询价详情',
    '发布时间：': '发布时间：',
    '审核拒绝原因：': '审核拒绝原因：',
    '询盘详情': '询盘详情',
    '预计采购金额：': '预计采购金额：',
    '展开全部': '展开全部',
    '收起': '收起',
    '期望收货日期：': '期望收货日期：',
    '指定发货方式：': '指定发货方式：',
    '报价列表': '报价列表',
    '收货人信息': '收货人信息',
    '报价总金额': '报价总金额',
    '供应方': '供应方',
    '报价产品': '报价产品',
    '报价时间': '报价时间',
    '接受报价': '接受报价',
    '洽谈': '洽谈',
    '官方推荐商品': '官方推荐商品',
    '供应商信息': '供应商信息',
    '查看报价': '查看报价',
    '联系电话：': '联系电话：',
    '确认接受该报价？': '确认接受该报价？',
    '状态：': '状态：',
    '报价详情': '报价详情',
    '报价时间：': '报价时间：',
    '报价供应方：': '报价供应方：',
    '订单详情': '订单详情',
    '手机号码：': '手机号码：',
    '合约状态:': '合约状态:',
    '发起合约': '发起合约',
    '编辑合约': '编辑合约',
    '请选择正确的合约接受截止时间': '请选择正确的合约接受截止时间',
    '请填写正确的单价': '请填写正确的单价',
    '请选择正确的最晚交付日期': '请选择正确的最晚交付日期',
    '详细信息报价后可见': '详细信息报价后可见',
    '报价状态：': '报价状态：',
    '默认地址': '默认地址',
    '全部地址': '全部地址',
    '设置为默认': '设置为默认',
    '新增地址': '新增地址',
    '暂无收货地址～': '暂无收货地址～',
    '确定删除该地址?': '确定删除该地址?',
    '删除地址': '删除地址',
    '取消收藏': '取消收藏',
    '确定取消收藏吗?': '确定取消收藏吗?',
    '取消收藏成功': '取消收藏成功',
    '取消收藏失败': '取消收藏失败',
    '补充以下信息完成认证': '补充以下信息完成认证',
    '请输入公司名称': '请输入公司名称',
    '公司名称': '公司名称',
    '请输入设计师姓名': '请输入设计师姓名',
    '设计师姓名': '设计师姓名',
    '认证审核中...': '认证审核中...',
    '已认证为设计师用户！': '已认证为设计师用户！',
    '重新申请': '重新申请',
    '审核未通过': '审核未通过',
    '请选择供应商': '请选择供应商',
    '请输入正确的公司名称': '请输入正确的公司名称',
    '企业会员权益': '企业会员权益',
    '信用账期服务，先采购后付款': '信用账期服务，先采购后付款',
    '折扣价': '折扣价',
    '信用帐期': '信用帐期',
    '产品折扣价采购': '产品折扣价采购',
    '物料管理': '物料管理',
    '海量产品物料管理': '海量产品物料管理',
    '组织管理': '组织管理',
    '管理本公司的设计师人员': '管理本公司的设计师人员',
    '公司信息': '公司信息',
    '公司名称：': '公司名称：',
    '请输入部门名称': '请输入部门名称',
    '部门名称：': '部门名称：',
    '申请认证': '申请认证',
    '请输入社会统一信用代码': '请输入社会统一信用代码',
    '请输入公司详细地址': '请输入公司详细地址',
    '社会统一信用代码：': '社会统一信用代码：',
    '所在地：': '所在地：',
    '请输入联系人名称': '请输入联系人名称',
    '请输入联系人手机号': '请输入联系人手机号',
    '企业邮箱：': '企业邮箱：',
    '请输入企业邮箱': '请输入企业邮箱',
    '营业执照信息': '营业执照信息',
    '法人身份信息': '法人身份信息',
    '营业执照：': '营业执照：',
    '身份证人像页：': '身份证人像页：',
    '身份证国徽页：': '身份证国徽页：',
    '提交': '提交',
    '审核中…': '审核中…',
    '认证成功！': '认证成功！',
    '认证失败！': '认证失败！',
    '失败原因：': '失败原因：',
    '重新认证': '重新认证',
    '请上传营业执照': '请上传营业执照',
    '请上传身份证国徽页': '请上传身份证国徽页',
    '请输入正确的社会统一信用代码': '请输入正确的社会统一信用代码',
    '请上传身份证人像页': '请上传身份证人像页',
    '发布招标': '发布招标',
    '请上传小于20M的图片': '请上传小于20M的图片',
    '待平台审核': '待平台审核',
    '待投标': '待投标',
    '已结束': '已结束',
    '已终止': '已终止',
    '终止招标': '终止招标',
    '查看终止公告': '查看终止公告',
    '查看中标公告': '查看中标公告',
    '确认删除该招标？': '确认删除该招标？',
    '确认结束报价？结束报价后将不再接收针对该询盘的报价。': '确认结束报价？结束报价后将不再接收针对该询盘的报价。',
    '暂无投标邀请': '暂无投标邀请',
    '确认删除该投标？': '确认删除该投标？',
    '暂无报价': '暂无报价',
    '产品收藏列表': '产品收藏列表',
    '关注的供应商': '关注的供应商',
    '取消全选': '取消全选',
    '暂无收藏供应商': '暂无收藏供应商',
    '确定取消关注吗?': '确定取消关注吗?',
    '暂无收藏产品': '暂无收藏产品',
    '请选择产品': '请选择产品',
    '采购中': '采购中',
    '待审核': '待审核',
    '已完成': '已完成',
    '请输入申请单号/项目名称': '请输入申请单号/项目名称',
    '申请单号': '申请单号',
    '已拒绝': '已拒绝',
    '项目名称': '项目名称',
    '申请时间': '申请时间',
    '拒绝理由': '拒绝理由',
    '查看订单': '查看订单',
    '暂无采购申请数据': '暂无采购申请数据',
    '确定删除该条采购申请?': '确定删除该条采购申请?',
    '申请单号：': '申请单号：',
    '申请单详情': '申请单详情',
    '设计师信息': '设计师信息',
    '设计师名称：': '设计师名称：',
    '申请时间：': '申请时间：',
    '用户名：': '用户名：',
    '项目信息': '项目信息',
    '项目名称： --': '项目名称： --',
    '订单信息': '订单信息',
    '名称：': '名称：',
    '规格：': '规格：',
    '确定要清空吗?': '确定要清空吗?',
    '订货编码': '订货编码',
    '确定删除足迹吗?': '确定删除足迹吗?',
    '个人用户': '个人用户',
    '认证设计师': '认证设计师',
    '认证企业': '认证企业',
    '查看全部订单': '查看全部订单',
    '待发货': '待发货',
    '部分发货': '部分发货',
    '待付款': '待付款',
    '退款售后': '退款售后',
    '进行中的订单': '进行中的订单',
    '订单状态': '订单状态',
    '待收货': '待收货',
    '订单号': '订单号',
    '采购单号': '采购单号',
    '这里空空如也，快去挑选合适的产品吧~': '这里空空如也，快去挑选合适的产品吧~',
    '常购产品': '常购产品',
    '暂无常购产品～': '暂无常购产品～',
    '暂无商品~': '暂无商品~',
    '供应商关注': '供应商关注',
    '加载中....': '加载中....',
    '暂无收藏产品～': '暂无收藏产品～',
    '暂无关注供应商～': '暂无关注供应商～',
    '供应商 : ': '供应商 : ',
    '暂无足迹～': '暂无足迹～',
    '从个人中心进入 供应商': '从个人中心进入 供应商',
    '用户头像：': '用户头像：',
    '仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。': '仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。',
    '真实姓名：': '真实姓名：',
    '昵称：': '昵称：',
    '性别：': '性别：',
    '男': '男',
    '保密': '保密',
    '生日：': '生日：',
    '年': '年',
    '女': '女',
    '月': '月',
    '所属企业：': '所属企业：',
    '日': '日',
    '上传图片成功': '上传图片成功',
    '图像应小于4MB': '图像应小于4MB',
    '上传图片失败': '上传图片失败',
    '请输入正确昵称': '请输入正确昵称',
    '真实姓名包含特殊字符，请重新输入': '真实姓名包含特殊字符，请重新输入',
    '请输入物料编码': '请输入物料编码',
    '产品名称：': '产品名称：',
    '请输入订货编码': '请输入订货编码',
    '供应商：': '供应商：',
    '新增物料编码': '新增物料编码',
    '快速添加': '快速添加',
    '规格': '规格',
    '模版导入': '模版导入',
    '启用状态': '启用状态',
    '确定删除此编码？': '确定删除此编码？',
    '导入物料编码': '导入物料编码',
    '请先下载导入模版，并按照批注中的要求填写数据，未按要求填写将会导致导入失败': '请先下载导入模版，并按照批注中的要求填写数据，未按要求填写将会导致导入失败',
    '操作提示': '操作提示',
    '请选择.xls文件，每次只能导入一个文件，建议每次导入不超过': '请选择.xls文件，每次只能导入一个文件，建议每次导入不超过',
    '条产品数据': '条产品数据',
    '下载导入模版': '下载导入模版',
    '上传文件': '上传文件',
    '下载标准模版': '下载标准模版',
    '完成': '完成',
    '点击上传或者拖拽文件到该区域即可': '点击上传或者拖拽文件到该区域即可',
    '下载错误表格': '下载错误表格',
    '下一步': '下一步',
    '上传失败！': '上传失败！',
    '您可以': '您可以',
    '查看错误原因，修改后重新上传。': '查看错误原因，修改后重新上传。',
    '导入成功！': '导入成功！',
    '请输入产品的订货编码': '请输入产品的订货编码',
    '您可以关闭该弹框查看已导入的物料编码，或是继续导入。': '您可以关闭该弹框查看已导入的物料编码，或是继续导入。',
    '继续导入': '继续导入',
    '产品信息：': '产品信息：',
    '添加物料编码': '添加物料编码',
    '货品不存在': '货品不存在',
    '是否启用：': '是否启用：',
    '由购物车添加': '由购物车添加',
    '由已购产品添加': '由已购产品添加',
    '请填订货编码': '请填订货编码',
    '暂无产品': '暂无产品',
    '由收藏产品添加': '由收藏产品添加',
    '请填物料编码': '请填物料编码',
    '请先获取产品信息': '请先获取产品信息',
    '标准模板': '标准模板',
    '错误表格': '错误表格',
    '编辑物料编码': '编辑物料编码',
    '请选择产品!': '请选择产品!',
    '上传的文件格式不正确': '上传的文件格式不正确',
    '收入': '收入',
    '选中产品的物料编码不能为空!': '选中产品的物料编码不能为空!',
    '支出': '支出',
    '收入/支出': '收入/支出',
    '日期': '日期',
    '暂无积分记录~': '暂无积分记录~',
    '手机号码': '手机号码',
    '详细说明': '详细说明',
    '绑定手机：': '绑定手机：',
    '尚未绑定手机号': '尚未绑定手机号',
    '若手机丢失或停用，请及时更换': '若手机丢失或停用，请及时更换',
    '修改号码': '修改号码',
    '绑定号码': '绑定号码',
    '电子邮箱': '电子邮箱',
    '绑定邮箱：': '绑定邮箱：',
    '若邮箱已停用，请及时更换': '若邮箱已停用，请及时更换',
    '修改邮箱': '修改邮箱',
    '尚未绑定邮箱': '尚未绑定邮箱',
    '安全性高的密码可以保障您的账号安全，建议密码为6～20位,由英文、数字或符号的组合': '安全性高的密码可以保障您的账号安全，建议密码为6～20位,由英文、数字或符号的组合',
    '绑定邮箱': '绑定邮箱',
    '修改密码': '修改密码',
    '设置密码': '设置密码',
    '在使用信用支付时需输入支付密码以保证您的资金安全': '在使用信用支付时需输入支付密码以保证您的资金安全',
    '绑定电子邮箱': '绑定电子邮箱',
    '修改电子邮箱': '修改电子邮箱',
    '请输入邮箱验证码': '请输入邮箱验证码',
    '请输入新邮箱': '请输入新邮箱',
    '当前邮箱': '当前邮箱',
    '为了保障您的账号安全，变更重要信息需进行身份验证。': '为了保障您的账号安全，变更重要信息需进行身份验证。',
    '变更过程中有任何疑问请联系在线客服解决。': '变更过程中有任何疑问请联系在线客服解决。',
    '温馨提示': '温馨提示',
    '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。': '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。',
    '请先绑定手机号，再进行登陆密码操作!': '请先绑定手机号，再进行登陆密码操作!',
    '修改': '修改',
    '当前手机号': '当前手机号',
    '请再次输入密码': '请再次输入密码',
    '请输入新密码': '请输入新密码',
    '请输入原密码': '请输入原密码',
    '请再次输入新密码': '请再次输入新密码',
    '请输入旧密码': '请输入旧密码',
    '复杂的密码可使账号更安全且建议定期更换密码。': '复杂的密码可使账号更安全且建议定期更换密码。',
    '请先绑定手机号，再进行操作!': '请先绑定手机号，再进行操作!',
    '请先绑定手机号，再进行支付密码操作!': '请先绑定手机号，再进行支付密码操作!',
    '请输入一致的新密码': '请输入一致的新密码',
    '请输入一致的密码': '请输入一致的密码',
    '请再次输入支付密码': '请再次输入支付密码',
    '请输入新支付密码': '请输入新支付密码',
    '修改支付密码': '修改支付密码',
    '请输入原支付密码': '请输入原支付密码',
    '请输入旧支付密码': '请输入旧支付密码',
    '请再次输入新支付密码': '请再次输入新支付密码',
    '设置支付密码': '设置支付密码',
    '请输入一致的新支付密码': '请输入一致的新支付密码',
    '请输入一致的支付密码': '请输入一致的支付密码',
    '重置支付密码': '重置支付密码',
    '请输入新手机号': '请输入新手机号',
    '请先绑定手机号，再进行重置支付密码操作!': '请先绑定手机号，再进行重置支付密码操作!',
    '（纸质合同的申请需要由买家下载纸质合同并签署后邮寄给相应的供应商）': '（纸质合同的申请需要由买家下载纸质合同并签署后邮寄给相应的供应商）',
    '合同申请': '合同申请',
    '选择订单': '选择订单',
    '信息确认': '信息确认',
    '合同申请成功': '合同申请成功',
    '完成时间：': '完成时间：',
    '下单时间：': '下单时间：',
    '请输入采购单号': '请输入采购单号',
    '请输入供应商': '请输入供应商',
    '请输入订单号': '请输入订单号',
    '个订单': '个订单',
    '请确认需申请合同的订单': '请确认需申请合同的订单',
    '已选择以下': '已选择以下',
    '下单时间': '下单时间',
    '订单金额': '订单金额',
    '请确认并填写以下信息': '请确认并填写以下信息',
    '暂无待申请合同': '暂无待申请合同',
    '自定义合同号：': '自定义合同号：',
    '需方信息：': '需方信息：',
    '请输入自定义合同号': '请输入自定义合同号',
    '合同备注：': '合同备注：',
    '请输入合同备注': '请输入合同备注',
    '单位名称：': '单位名称：',
    '请输入单位名称': '请输入单位名称',
    '请输入单位地址': '请输入单位地址',
    '法定代理人：': '法定代理人：',
    '单位地址：': '单位地址：',
    '请输入委托代理人': '请输入委托代理人',
    '请输入法定代理人': '请输入法定代理人',
    '请输入电话': '请输入电话',
    '委托代理人：': '委托代理人：',
    '单位邮编：': '单位邮编：',
    '请输入单位邮编': '请输入单位邮编',
    '请输入单位传真': '请输入单位传真',
    '单位传真：': '单位传真：',
    '请输入开户银行账号': '请输入开户银行账号',
    '请输入开户银行名称': '请输入开户银行名称',
    '银行账号：': '银行账号：',
    '请输入税号': '请输入税号',
    '税号：': '税号：',
    '身份证号：': '身份证号：',
    '请输入开户身份证号': '请输入开户身份证号',
    '您的合同（草稿）创建成功': '您的合同（草稿）创建成功',
    '合同号': '合同号',
    '当前创建的为合同草稿，可下载预览；转为正式合同后具有法律效力，落款章有效。': '当前创建的为合同草稿，可下载预览；转为正式合同后具有法律效力，落款章有效。',
    '转为正式': '转为正式',
    '开票方式：': '开票方式：',
    '预览': '预览',
    '申请合同': '申请合同',
    '上一步': '上一步',
    '申请数量': '申请数量',
    '申请数量：': '申请数量：',
    '请输入申请数量': '请输入申请数量',
    '转为正式合同': '转为正式合同',
    '请输入正确的数量': '请输入正确的数量',
    '转为正式电子合同': '转为正式电子合同',
    '请设置选中订单的申请数量': '请设置选中订单的申请数量',
    '申请数量最多为': '申请数量最多为',
    '转为正式纸质合同': '转为正式纸质合同',
    '请输入正确的单位邮编': '请输入正确的单位邮编',
    '请输入正确的电话': '请输入正确的电话',
    '请输入正确的单位传真': '请输入正确的单位传真',
    '请输入正确的银行账号': '请输入正确的银行账号',
    '请输入正确的税号': '请输入正确的税号',
    '请输入正确的身份证号': '请输入正确的身份证号',
    '请选择合同类型': '请选择合同类型',
    '获取认证信息失败': '获取认证信息失败',
    '已认证': '已认证',
    '请在合同列表页查看': '请在合同列表页查看',
    '个人实名认证': '个人实名认证',
    '未认证': '未认证',
    '企业实名认证': '企业实名认证',
    '去认证': '去认证',
    '完善身份信息': '完善身份信息',
    '填写验证码': '填写验证码',
    '完成认证': '完成认证',
    '请输入身份证号': '请输入身份证号',
    '请输入身份证上姓名': '请输入身份证上姓名',
    '请输入用以上身份证号办理的手机号': '请输入用以上身份证号办理的手机号',
    '《数字证书使用协议》': '《数字证书使用协议》',
    '验证码：': '验证码：',
    '请勾选后再进行下一步': '请勾选后再进行下一步',
    '我已阅读并确认': '我已阅读并确认',
    's后可重新发送': 's后可重新发送',
    '认证成功!': '认证成功!',
    '已成功为您发放CA 证书。仅在您同意的情况下，您的证书方可被调用签署相关的电子合同等法律文件。': '已成功为您发放CA 证书。仅在您同意的情况下，您的证书方可被调用签署相关的电子合同等法律文件。',
    '立即返回': '立即返回',
    '请输入法定代表人名称': '请输入法定代表人名称',
    '法定代表人名称：': '法定代表人名称：',
    's后自动返回至合同管理页': 's后自动返回至合同管理页',
    '请输入法定代表人身份证号': '请输入法定代表人身份证号',
    '认证方式：': '认证方式：',
    '法定代表人身份证号：': '法定代表人身份证号：',
    '我是法人': '我是法人',
    '我是经办人': '我是经办人',
    '法定代表人手机号：': '法定代表人手机号：',
    '请输入法定代表人手机号': '请输入法定代表人手机号',
    '请输入经办人名称': '请输入经办人名称',
    '经办人身份证号：': '经办人身份证号：',
    '请输入经办人身份证号': '请输入经办人身份证号',
    '经办人名称：': '经办人名称：',
    '经办人手机号：': '经办人手机号：',
    '请输入经办人手机号': '请输入经办人手机号',
    '授权书：': '授权书：',
    '上传图片': '上传图片',
    '实名认证': '实名认证',
    '请上传文件': '请上传文件',
    '请输入真实姓名': '请输入真实姓名',
    '请输入正确的真实姓名': '请输入正确的真实姓名',
    '请输入正确的法定代表人身份证号': '请输入正确的法定代表人身份证号',
    '请上传授权书': '请上传授权书',
    '暂无合同详情': '暂无合同详情',
    '请输入正确的经办人身份证号': '请输入正确的经办人身份证号',
    '个人: ': '个人: ',
    '合同管理': '合同管理',
    '认证信息': '认证信息',
    '签名': '签名',
    '商户: ': '商户: ',
    '未设置': '未设置',
    '企业: ': '企业: ',
    '合同号：': '合同号：',
    '创建时间：': '创建时间：',
    '请输入合同号': '请输入合同号',
    '索': '索',
    '搜': '搜',
    '合同金额': '合同金额',
    '重': '重',
    '置': '置',
    '创建时间': '创建时间',
    '合同形式': '合同形式',
    '包含订单': '包含订单',
    '查看': '查看',
    '作废': '作废',
    '备注': '备注',
    '暂无申请记录': '暂无申请记录',
    '编辑备注': '编辑备注',
    '请输入备注': '请输入备注',
    '签署验证': '签署验证',
    '因您尚未完成实名认证，目前仅支持申请纸质合同。': '因您尚未完成实名认证，目前仅支持申请纸质合同。',
    '将调用您的数字证书进行签署，您正在安全签约环境中，请放心签署。': '将调用您的数字证书进行签署，您正在安全签约环境中，请放心签署。',
    '即将向您': '即将向您',
    '的手机号码发送确认签署验证码': '的手机号码发送确认签署验证码',
    '请输入手机验证码': '请输入手机验证码',
    '请尽快输入验证码完成签署': '请尽快输入验证码完成签署',
    '待选择合同类型': '待选择合同类型',
    's后重新发送': 's后重新发送',
    '正式': '正式',
    '待供方处理': '待供方处理',
    '废弃': '废弃',
    '请输入备注信息': '请输入备注信息',
    '确定作废该合同?': '确定作废该合同?',
    '验证手机号获取错误': '验证手机号获取错误',
    '已结算': '已结算',
    '已逾期': '已逾期',
    '待结算': '待结算',
    '信用账单号/采购单号/订单号': '信用账单号/采购单号/订单号',
    '最后结算日': '最后结算日',
    '账单生成时间': '账单生成时间',
    '账单金额': '账单金额',
    '应结算金额': '应结算金额',
    '信用账单号：': '信用账单号：',
    '结算状态': '结算状态',
    '即将逾期': '即将逾期',
    '汇款时间：': '汇款时间：',
    '确认时间：': '确认时间：',
    '暂无信用账单数据': '暂无信用账单数据',
    '取消合并': '取消合并',
    '结算': '结算',
    '暂无订单～': '暂无订单～',
    '待结算金额：': '待结算金额：',
    '注意事项': '注意事项',
    '您尚未认证成为企业用户，认证后即可享受信用支付服务。': '您尚未认证成为企业用户，认证后即可享受信用支付服务。',
    '（转账必读）': '（转账必读）',
    '上传凭证成功': '上传凭证成功',
    '已完成汇款': '已完成汇款',
    '上传凭证失败': '上传凭证失败',
    '请上传': '请上传',
    '汇款信息的汇款凭证': '汇款信息的汇款凭证',
    '付款成功': '付款成功',
    '编辑成功': '编辑成功',
    '复制成功': '复制成功',
    '拒绝理由：': '拒绝理由：',
    '拒绝采购': '拒绝采购',
    '已超过限定数量': '已超过限定数量',
    '您的浏览器不支持快捷复制': '您的浏览器不支持快捷复制',
    '请输入拒绝理由': '请输入拒绝理由',
    '申请单号/设计师名称/用户名/手机号': '申请单号/设计师名称/用户名/手机号',
    '设计师名称': '设计师名称',
    '用户名': '用户名',
    '采购申请单': '采购申请单',
    '手机号': '手机号',
    '消费记录': '消费记录',
    '可申请企业': '可申请企业',
    '申请记录': '申请记录',
    '授信额度：': '授信额度：',
    '剩余可用额度：': '剩余可用额度：',
    '审核中': '审核中',
    '账期：': '账期：',
    '申请授信': '申请授信',
    '申请失败,您可以查看申请记录或重新申请。': '申请失败,您可以查看申请记录或重新申请。',
    '授信额度': '授信额度',
    '暂无可申请企业': '暂无可申请企业',
    '授信可用额度': '授信可用额度',
    '申请企业': '申请企业',
    '暂无消费记录': '暂无消费记录',
    '授权信息': '授权信息',
    '授信额度：¥': '授信额度：¥',
    '审核状态': '审核状态',
    '重新审核': '重新审核',
    '更新时间': '更新时间',
    '授信企业': '授信企业',
    '申请已提交': '申请已提交',
    '可开票金额': '可开票金额',
    '可开票金额=总计消费可开票金额-历史已开票金额': '可开票金额=总计消费可开票金额-历史已开票金额',
    '去开票': '去开票',
    '发票抬头': '发票抬头',
    '管理发票抬头': '管理发票抬头',
    '暂无发票': '暂无发票',
    '电子邮箱:': '电子邮箱:',
    '寄送信息': '寄送信息',
    '管理寄送信息': '管理寄送信息',
    '开票记录': '开票记录',
    '暂无信息': '暂无信息',
    '请输入发票号': '请输入发票号',
    '发票号：': '发票号：',
    '发票状态：': '发票状态：',
    '请输入订单编号': '请输入订单编号',
    '开票主体': '开票主体',
    '发票类型': '发票类型',
    '发票号': '发票号',
    '暂无开票记录': '暂无开票记录',
    '发票金额': '发票金额',
    '发票状态': '发票状态',
    '发票备注': '发票备注',
    '发票性质': '发票性质',
    '纸质': '纸质',
    '查看物流': '查看物流',
    '发票备注：': '发票备注：',
    '编辑发票备注': '编辑发票备注',
    '电子': '电子',
    '物流信息': '物流信息',
    '请输入发票备注': '请输入发票备注',
    '承运人': '承运人',
    '联系电话': '联系电话',
    '开票中': '开票中',
    '联系人': '联系人',
    '运单号': '运单号',
    '已开票': '已开票',
    '已作废': '已作废',
    '开票': '开票',
    '确定作废？': '确定作废？',
    '发票备注不能为空': '发票备注不能为空',
    '编辑失败': '编辑失败',
    '选择待开票数据': '选择待开票数据',
    '确定发票信息和地址': '确定发票信息和地址',
    '支付时间：': '支付时间：',
    '订单编号：': '订单编号：',
    '可开发票金额：': '可开发票金额：',
    '订单编号': '订单编号',
    '支付时间': '支付时间',
    '暂无待开票数据': '暂无待开票数据',
    '开票金额：': '开票金额：',
    '开票主体：': '开票主体：',
    '增值税普通发票（电子）': '增值税普通发票（电子）',
    '增值税普通发票（纸质）': '增值税普通发票（纸质）',
    '请选择发票抬头': '请选择发票抬头',
    '增值税专用发票（纸质）': '增值税专用发票（纸质）',
    '寄送信息：': '寄送信息：',
    '电子邮箱：': '电子邮箱：',
    '选择邮箱': '选择邮箱',
    '该备注信息会展示在发票上，如有需要请录入，否则无需录入任何信息。': '该备注信息会展示在发票上，如有需要请录入，否则无需录入任何信息。',
    '建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到。': '建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到。',
    '发票抬头：': '发票抬头：',
    '编辑申请数量': '编辑申请数量',
    '选择地址': '选择地址',
    '默认邮箱': '默认邮箱',
    '申请数量最大为': '申请数量最大为',
    '暂无地址信息': '暂无地址信息',
    '寄送信息管理': '寄送信息管理',
    '寄送地址': '寄送地址',
    '收件人姓名': '收件人姓名',
    '暂无地址，请': '暂无地址，请',
    '设为默认': '设为默认',
    '新增邮箱': '新增邮箱',
    '地址': '地址',
    '添加地址': '添加地址',
    '确定删除该邮箱?': '确定删除该邮箱?',
    '暂无数据，请': '暂无数据，请',
    '抬头类型：': '抬头类型：',
    '请输入发票抬头': '请输入发票抬头',
    '公司': '公司',
    '请输入个人真实姓名': '请输入个人真实姓名',
    '请输入统一社会信用代码': '请输入统一社会信用代码',
    '注册场所地址：': '注册场所地址：',
    '统一社会信用代码：': '统一社会信用代码：',
    '请输入注册场所地址': '请输入注册场所地址',
    '请输入银行账号': '请输入银行账号',
    '注册固定电话：': '注册固定电话：',
    '请输入注册固定电话': '请输入注册固定电话',
    '默认发票：': '默认发票：',
    '是': '是',
    '否': '否',
    '请输入发票抬头!': '请输入发票抬头!',
    '添加发票抬头': '添加发票抬头',
    '请输入正确的发票抬头!': '请输入正确的发票抬头!',
    '请输入统一社会信用代码!': '请输入统一社会信用代码!',
    '请输入正确的统一社会信用代码!': '请输入正确的统一社会信用代码!',
    '请输入注册固定电话!': '请输入注册固定电话!',
    '请输入注册场所地址!': '请输入注册场所地址!',
    '请输入正确的注册固定电话!': '请输入正确的注册固定电话!',
    '请输入银行账号!': '请输入银行账号!',
    '类型': '类型',
    '发票抬头管理': '发票抬头管理',
    '请输入开户银行名称!': '请输入开户银行名称!',
    '新增发票抬头': '新增发票抬头',
    '社会统一信用代码': '社会统一信用代码',
    '编辑发票抬头': '编辑发票抬头',
    '确定删除该发票抬头?': '确定删除该发票抬头?',
    '发票金额：': '发票金额：',
    '发票详情': '发票详情',
    '发票信息': '发票信息',
    '备注信息：': '备注信息：',
    '发票性质：': '发票性质：',
    '收票人：': '收票人：',
    '收票信息': '收票信息',
    '邮箱：': '邮箱：',
    '暂无订单信息': '暂无订单信息',
    '发票详情数据有误': '发票详情数据有误',
    '卖方合约订单': '卖方合约订单',
    '买方合约订单': '买方合约订单',
    '全部状态': '全部状态',
    '待确认': '待确认',
    '已达成': '已达成',
    '已失效': '已失效',
    '合约单号': '合约单号',
    '买方': '买方',
    '卖方': '卖方',
    '合约产品': '合约产品',
    '合约金额(元)': '合约金额(元)',
    '对应询盘': '对应询盘',
    '确认删除该询盘？删除后数据不可恢复。': '确认删除该询盘？删除后数据不可恢复。',
    '去确认': '去确认',
    '报价结束': '报价结束',
    '待报价': '待报价',
    '审核拒绝': '审核拒绝',
    '已关闭': '已关闭',
    '合约中': '合约中',
    '结束时间': '结束时间',
    '开始时间': '开始时间',
    '预计采购金额(元)': '预计采购金额(元)',
    '已获报价': '已获报价',
    '发布时间': '发布时间',
    '份报价': '份报价',
    '结束报价': '结束报价',
    '查看合约': '查看合约',
    '报价总金额(元)': '报价总金额(元)',
    '待洽谈': '待洽谈',
    '状态:': '状态:',
    '待发起合约': '待发起合约',
    '去注册': '去注册',
    '我已知悉？': '我已知悉？',
    '绑定手机号': '绑定手机号',
    '绑定': '绑定',
    '该手机号已被绑定，请更换其他手机号': '该手机号已被绑定，请更换其他手机号',
    '的绑定关系': '的绑定关系',
    '更新信息：授权信息将绑定到账号': '更新信息：授权信息将绑定到账号',
    '继续绑定：将解除与账号': '继续绑定：将解除与账号',
    '继续绑定': '继续绑定',
    '上': '上',
    '更新信息': '更新信息',
    '去登录': '去登录',
    '想起密码？': '想起密码？',
    '请输入6～20位英文、数字、符号': '请输入6～20位英文、数字、符号',
    '还没注册？': '还没注册？',
    '已有账号？': '已有账号？',
    '请输入图形验证码': '请输入图形验证码',
    '找回密码': '找回密码',
    '注册账号': '注册账号',
    '我同意': '我同意',
    '《用户注册协议》': '《用户注册协议》',
    '请同意用户注册协议及隐私政策': '请同意用户注册协议及隐私政策',
    '已有账号，去登录': '已有账号，去登录',
    '总金额': '总金额',
    '《隐私政策》': '《隐私政策》',
    '可用余额': '可用余额',
    '余额明细': '余额明细',
    '充值': '充值',
    '充值明细': '充值明细',
    '暂无余额明细~': '暂无余额明细~',
    '交易日期': '交易日期',
    '充值金额': '充值金额',
    '暂无充值明细~': '暂无充值明细~',
    '变动原因': '变动原因',
    '我的优惠卷': '我的优惠卷',
    '未使用': '未使用',
    '已使用': '已使用',
    '已过期': '已过期',
    '1.填写充值金额': '1.填写充值金额',
    '3.充值完成': '3.充值完成',
    '请输入金额': '请输入金额',
    '填写充值金额': '填写充值金额',
    '2.在线支付': '2.在线支付',
    '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账': '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账',
    '充值账户': '充值账户',
    '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；': '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；',
    '2.充值金额输入值必须是不小于1且不大于5000的正整数；': '2.充值金额输入值必须是不小于1且不大于5000的正整数；',
    '请使用手机微信扫描下方二维码进行支付': '请使用手机微信扫描下方二维码进行支付',
    '应付金额': '应付金额',
    '您正在充值余额，请尽快支付': '您正在充值余额，请尽快支付',
    '选择充值方式': '选择充值方式',
    '立即充值': '立即充值',
    '3.充值完成后，您可至会员中心查看充值记录。': '3.充值完成后，您可至会员中心查看充值记录。',
    '微信支付支付': '微信支付支付',
    '如二维码过期，': '如二维码过期，',
    '重新获取二维码。': '重新获取二维码。',
    '使用微信扫码支付': '使用微信扫码支付',
    '超过充值金额上限': '超过充值金额上限',
    '请输入充值金额': '请输入充值金额',
    '充值成功,2s后自动跳转充值列表': '充值成功,2s后自动跳转充值列表',
    '充值成功,2s后自动跳转支付页面': '充值成功,2s后自动跳转支付页面',
    '退款金额': '退款金额',
    '申请类型': '申请类型',
    '退款单号': '退款单号',
    '仅退款': '仅退款',
    '发货': '发货',
    '添加/编辑': '添加/编辑',
    '（同订单商品可批量申请）': '（同订单商品可批量申请）',
    '添加和编辑': '添加和编辑',
    '退货退款': '退货退款',
    '同订单产品可批量申请': '同订单产品可批量申请',
    '含运费': '含运费',
    '退款金额不可修改，最多': '退款金额不可修改，最多',
    '修改金额': '修改金额',
    '退款金额可修改，最多': '退款金额可修改，最多',
    '不含运费': '不含运费',
    '请输入退款金额': '请输入退款金额',
    '请输入申请件数': '请输入申请件数',
    '申请件数': '申请件数',
    '货物状态': '货物状态',
    '请选择退款原因': '请选择退款原因',
    '退款原因': '退款原因',
    '请输入问题描述(选填)': '请输入问题描述(选填)',
    '问题描述': '问题描述',
    '上传凭证': '上传凭证',
    '总共上传': '总共上传',
    '张图片': '张图片',
    '请输入接收退款的银行账号': '请输入接收退款的银行账号',
    '请输入接收退款银行账号的开户行': '请输入接收退款银行账号的开户行',
    '退款账户需和付款账户保持一致，否则无法退款。': '退款账户需和付款账户保持一致，否则无法退款。',
    '退款账户：': '退款账户：',
    '批量售后产品选择': '批量售后产品选择',
    '开户行：': '开户行：',
    '描述文字': '描述文字',
    '未收到货': '未收到货',
    '已收到货': '已收到货',
    '退款退货': '退款退货',
    '不可超出最大申请数量': '不可超出最大申请数量',
    '退款金额不可为0或者负值！': '退款金额不可为0或者负值！',
    '最多上传5张！': '最多上传5张！',
    '确认收货': '确认收货',
    '不可超过最大退款金额！': '不可超过最大退款金额！',
    '类型必须为图片': '类型必须为图片',
    '立即支付': '立即支付',
    '取消订单': '取消订单',
    '修改地址': '修改地址',
    '支付订单': '支付订单',
    '删除订单': '删除订单',
    '商家发货': '商家发货',
    '送货方式': '送货方式',
    '交易关闭': '交易关闭',
    '快递': '快递',
    '无需物流': '无需物流',
    '暂无物流信息': '暂无物流信息',
    '卖家': '卖家',
    '不需要发票': '不需要发票',
    '订单取消备注': '订单取消备注',
    '满优惠': '满优惠',
    '交易流水号': '交易流水号',
    '订单备注': '订单备注',
    '运费金额': '运费金额',
    '积分抵扣': '积分抵扣',
    '店铺优惠券': '店铺优惠券',
    '实际金额': '实际金额',
    '取消订单理由': '取消订单理由',
    '请选择取消理由': '请选择取消理由',
    '取消该订单定金不予退还,确定取消?': '取消该订单定金不予退还,确定取消?',
    '确认删除该订单?': '确认删除该订单?',
    '取消订单成功': '取消订单成功',
    '确认收货成功': '确认收货成功',
    '确认收货?': '确认收货?',
    '删除订单成功': '删除订单成功',
    '切换地址成功': '切换地址成功',
    '订单：': '订单：',
    '评价订单': '评价订单',
    '综合': '综合',
    '服务': '服务',
    '服务态度': '服务态度',
    '物流': '物流',
    '发货速度': '发货速度',
    '描述相符': '描述相符',
    '产品评分': '产品评分',
    '评价晒单': '评价晒单',
    '晒图': '晒图',
    '请输入内容': '请输入内容',
    '您的评价可以帮助他人更真实的了解产品': '您的评价可以帮助他人更真实的了解产品',
    '发表评价': '发表评价',
    '交易评价/晒单': '交易评价/晒单',
    '从多个角度评价产品，可以帮助更多想买的人': '从多个角度评价产品，可以帮助更多想买的人',
    '待评订单': '待评订单',
    '评价小贴士': '评价小贴士',
    '金额': '金额',
    '支付金额': '支付金额',
    '订单结束': '订单结束',
    '供应商审核': '供应商审核',
    '交易取消': '交易取消',
    '采购付款': '采购付款',
    '供应商发货': '供应商发货',
    '供应商名称：': '供应商名称：',
    '支付方式：': '支付方式：',
    '交易完成': '交易完成',
    '查看账单': '查看账单',
    '结算单号：': '结算单号：',
    '信用账单信息': '信用账单信息',
    '结算状态：': '结算状态：',
    '最后结算日：': '最后结算日：',
    '期望交货时间：': '期望交货时间：',
    '等待供应商审核': '等待供应商审核',
    '等待供应商报价': '等待供应商报价',
    '结算金额：': '结算金额：',
    '供应商审核通过，等待采购付款': '供应商审核通过，等待采购付款',
    '等待供应商发货': '等待供应商发货',
    '供应商已分批发货': '供应商已分批发货',
    '供应商审核拒绝，订单结束': '供应商审核拒绝，订单结束',
    '完成交易': '完成交易',
    '等待买家确认收货': '等待买家确认收货',
    '订单已取消': '订单已取消',
    '批次': '批次',
    '取消原因：': '取消原因：',
    '总价': '总价',
    '物料信息': '物料信息',
    '已申请合同': '已申请合同',
    '订单详情数据错误': '订单详情数据错误',
    '暂无详细物流信息': '暂无详细物流信息',
    '产品名称/订货编码/采购单号/订单号': '产品名称/订货编码/采购单号/订单号',
    '用户发货': '用户发货',
    '从订单列表进入 供应商': '从订单列表进入 供应商',
    '采购单号: ': '采购单号: ',
    '物流公司': '物流公司',
    '服务类型': '服务类型',
    '物流单号': '物流单号',
    '请输入物流单号': '请输入物流单号',
    '请选择物流公司!': '请选择物流公司!',
    '请输入物流单号！': '请输入物流单号！',
    '退货地址：': '退货地址：',
    '请输入正确的物流单号！': '请输入正确的物流单号！',
    '起订量: ': '起订量: ',
    '项目详情': '项目详情',
    '起订量:': '起订量:',
    '导出已选产品': '导出已选产品',
    '暂无产品数据': '暂无产品数据',
    '清空无效产品': '清空无效产品',
    '删除已选产品': '删除已选产品',
    '立即购买': '立即购买',
    '导出成功': '导出成功',
    '请选择要导出的产品': '请选择要导出的产品',
    '确定删除选中产品?': '确定删除选中产品?',
    '导出失败': '导出失败',
    '确定清空无效产品?': '确定清空无效产品?',
    '未开始': '未开始',
    '项目周期': '项目周期',
    '项目描述': '项目描述',
    '进行中': '进行中',
    '项目状态': '项目状态',
    '暂无我的项目数据': '暂无我的项目数据',
    '确定删除项目?': '确定删除项目?',
    '删除成功': '删除成功',
    '采购单号:': '采购单号:',
    '询价订单': '询价订单',
    '退款退货单号': '退款退货单号',
    '买家申请退款退货': '买家申请退款退货',
    '供应商拒绝退款': '供应商拒绝退款',
    '买家申请退款': '买家申请退款',
    '提交申请': '提交申请',
    '供应商确认退款': '供应商确认退款',
    '平台审核备注': '平台审核备注',
    '退款凭证': '退款凭证',
    '操作时间：': '操作时间：',
    '近三个月常购': '近三个月常购',
    '近半年常购': '近半年常购',
    '产品名称/订货编码': '产品名称/订货编码',
    '产品名称/订货编码/物料编码': '产品名称/订货编码/物料编码',
    '暂无常购清单数据': '暂无常购清单数据',
    '今年常购': '今年常购',
    '订单完成': '订单完成',
    '样品订单详情进入 供应商': '样品订单详情进入 供应商',
    '已取消': '已取消',
    '产品名称/订货编码/订单号': '产品名称/订货编码/订单号',
    '您尚未认证成为企业用户，认证后可创建子账号并使用子账号申请样品。': '您尚未认证成为企业用户，认证后可创建子账号并使用子账号申请样品。',
    '从订单列表进入': '从订单列表进入',
    '我的': '我的',
    '无': '无',
    '积分订单详情': '积分订单详情',
    '产品合计': '产品合计',
    '积分兑换订单': '积分兑换订单',
    '全部订单': '全部订单',
    '待支付': '待支付',
    '搜索订单': '搜索订单',
    '兑换时间：': '兑换时间：',
    '产品名称/订单号': '产品名称/订单号',
    '兑换单号: ': '兑换单号: ',
    '请输入手机号码': '请输入手机号码',
    '请设置6-20位字母、数字或符号组成的密码': '请设置6-20位字母、数字或符号组成的密码',
    '创建子账号': '创建子账号',
    '密码：': '密码：',
    '确认密码：': '确认密码：',
    '确认密码不一致': '确认密码不一致',
    '确认创建': '确认创建',
    '请输入账号名称': '请输入账号名称',
    '名称/用户名/手机号': '名称/用户名/手机号',
    '启用状态:': '启用状态:',
    '请选择启用状态': '请选择启用状态',
    '名称': '名称',
    '批量删除': '批量删除',
    '开启': '开启',
    '启用': '启用',
    '请选择要删除的子账号': '请选择要删除的子账号',
    '确定删除该子账号？删除后账号不可继续使用。': '确定删除该子账号？删除后账号不可继续使用。',
    '最多可创建10个子账号': '最多可创建10个子账号',
    '禁用': '禁用',
    '新密码：': '新密码：',
    '批量标为已读': '批量标为已读',
    '确定删除这条消息吗？': '确定删除这条消息吗？',
    '暂时没有消息！': '暂时没有消息！',
    '系统消息': '系统消息',
    '全部标为已读': '全部标为已读',
    '查看详情 >': '查看详情 >',
    '产品消息': '产品消息',
    '发票通知': '发票通知',
    '消息提醒': '消息提醒',
    '订单消息': '订单消息',
    '售后消息': '售后消息',
    '认证通知': '认证通知',
    '资产消息': '资产消息',
    '预约提醒': '预约提醒',
    '确定删除这些消息吗': '确定删除这些消息吗',
    '请先选择要操作的消息！': '请先选择要操作的消息！',
    '接收': '接收',
    '不接收': '不接收',
    '联系方式：': '联系方式：',
    '给商家留言': '给商家留言',
    '重新选择': '重新选择',
    '我要开发票': '我要开发票',
    '当前积分数：': '当前积分数：',
    '积分选择': '积分选择',
    '实付款：': '实付款：',
    '发票历史信息选择：': '发票历史信息选择：',
    '积分不足': '积分不足',
    '发票内容将显示产品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额': '发票内容将显示产品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额',
    '新增发票': '新增发票',
    '发票内容：': '发票内容：',
    '请在此填写发票抬头': '请在此填写发票抬头',
    '普通发票': '普通发票',
    '请在此填写纳税人识别号': '请在此填写纳税人识别号',
    '请在此填写部门名称': '请在此填写部门名称',
    '请输入注册电话': '请输入注册电话',
    '请输入注册地址': '请输入注册地址',
    '请输入开户银行': '请输入开户银行',
    '请输入收票人姓名': '请输入收票人姓名',
    '请输入银行账户': '请输入银行账户',
    '请输入收票人电话': '请输入收票人电话',
    '收票邮箱：': '收票邮箱：',
    '请输入收票人地址': '请输入收票人地址',
    '请输入收票邮箱': '请输入收票邮箱',
    '设为默认发票': '设为默认发票',
    '请填写注册地址': '请填写注册地址',
    '新增成功': '新增成功',
    '暂不使用': '暂不使用',
    '订单提交成功，请您尽快付款！ 订单号：': '订单提交成功，请您尽快付款！ 订单号：',
    '完成支付，否则订单会自动取消': '完成支付，否则订单会自动取消',
    '请您在提交订单后': '请您在提交订单后',
    '小时内': '小时内',
    '收起详情': '收起详情',
    '使用余额支付': '使用余额支付',
    '展开详情': '展开详情',
    '可用余额：': '可用余额：',
    '元，目前需要在线支付：': '元，目前需要在线支付：',
    '元）余额不足？': '元）余额不足？',
    '马上充值': '马上充值',
    '未设置支付密码，马上去设置': '未设置支付密码，马上去设置',
    '忘记密码?': '忘记密码?',
    '选择其它支付方式': '选择其它支付方式',
    '微信支付': '微信支付',
    '自营': '自营',
    '暂无可用的支付方式，平台正在紧急处理中～': '暂无可用的支付方式，平台正在紧急处理中～',
    '已兑换': '已兑换',
    '原价': '原价',
    '立即兑换': '立即兑换',
    '人气礼品': '人气礼品',
    '不能输入非0数字!': '不能输入非0数字!',
    '热门礼品': '热门礼品',
    '下单': '下单',
    '综合评分': '综合评分',
    '供应商评分': '供应商评分',
    '服务承诺': '服务承诺',
    ' 点这里': ' 点这里',
    '正品保障': '正品保障',
    '供应商首页': '供应商首页',
    '请输入关键词': '请输入关键词',
    '搜本店': '搜本店',
    '客服电话': '客服电话',
    '本店全部分类': '本店全部分类',
    '所有产品': '所有产品',
    '请输入...': '请输入...',
    '积分商城首页暂未装修': '积分商城首页暂未装修',
    '暂无产品～': '暂无产品～',
    '分类': '分类',
    '已开抢': '已开抢',
    '即将开始': '即将开始',
    '今日无秒杀': '今日无秒杀',
    '设置提醒': '设置提醒',
    '模块未开启': '模块未开启',
    '操作方式：': '操作方式：',
    '模板导入': '模板导入',
    '手动添加': '手动添加',
    '添加': '添加',
    '导入失败！': '导入失败！',
    '查询': '查询',
    '下载标准模板，按照模板要求填写数据。': '下载标准模板，按照模板要求填写数据。',
    '或重新上传。': '或重新上传。',
    '最低起购': '最低起购',
    '请输入数据': '请输入数据',
    '暂无匹配产品': '暂无匹配产品',
    '匹配成功': '匹配成功',
    '选型标准模板': '选型标准模板',
    '数量在1~99999区间': '数量在1~99999区间',
    '超出购买限制': '超出购买限制',
    '详情': '详情',
    '历史记录': '历史记录',
    '发起申请': '发起申请',
    '商务审核通过': '商务审核通过',
    '客服处理': '客服处理',
    '关闭理由：': '关闭理由：',
    '商务确认': '商务确认',
    '拒绝原因：': '拒绝原因：',
    '提交时间：': '提交时间：',
    '商务：': '商务：',
    '需求编号：': '需求编号：',
    '处理记录': '处理记录',
    '客服：': '客服：',
    '需求信息': '需求信息',
    '时间：': '时间：',
    '需求描述：': '需求描述：',
    '原始文件：': '原始文件：',
    '选型清单': '选型清单',
    '打开图片': '打开图片',
    '预览文件': '预览文件',
    '下载原始文件': '下载原始文件',
    '反馈结果：': '反馈结果：',
    '型号转换反馈文件': '型号转换反馈文件',
    '反馈清单': '反馈清单',
    '下载反馈文件': '下载反馈文件',
    '需求产品': '需求产品',
    '反馈备注：': '反馈备注：',
    '推荐产品': '推荐产品',
    '请输入非0正整数': '请输入非0正整数',
    '片式合金箔固定电阻器': '片式合金箔固定电阻器',
    '处理中': '处理中',
    '提交时间': '提交时间',
    '提交编码': '提交编码',
    '取消申请': '取消申请',
    '是否确认该条取消申请？取消后您可重新发起申请': '是否确认该条取消申请？取消后您可重新发起申请',
    '是否确认该条删除申请记录？': '是否确认该条删除申请记录？',
    '设计师下载清单，完成型号转换': '设计师下载清单，完成型号转换',
    '设计师需求描述，上传清单': '设计师需求描述，上传清单',
    '将文件拖入框内，或': '将文件拖入框内，或',
    '请输入需求描述，以便更好的辅助您进行选型': '请输入需求描述，以便更好的辅助您进行选型',
    '点击上传': '点击上传',
    '支持上传.xls文件和JPEG、PNG格式的图片': '支持上传.xls文件和JPEG、PNG格式的图片',
    '供应商根据描述优化选型': '供应商根据描述优化选型',
    '国产替代产品': '国产替代产品',
    '  联系电话: ': '  联系电话: ',
    '可替代产品': '可替代产品',
    '该系列已加入对比': '该系列已加入对比',
    '搜索历史：': '搜索历史：',
    '热门搜索：': '热门搜索：',
    '设计师描述需求，上传申请资料': '设计师描述需求，上传申请资料',
    '请选择数量': '请选择数量',
    '供应商客服处理': '供应商客服处理',
    '设计师查看样品申请单': '设计师查看样品申请单',
    '请输入您需要申请产品的名称、规格型号、数量等信息': '请输入您需要申请产品的名称、规格型号、数量等信息',
    '新增收货地址': '新增收货地址',
    '暂无收货地址': '暂无收货地址',
    '查看订单详情': '查看订单详情',
    '申请资料：': '申请资料：',
    '申请信息': '申请信息',
    '申请清单': '申请清单',
    '下载申请文件': '下载申请文件',
    '申请描述：': '申请描述：',
    '发送链接': '发送链接',
    '已读': '已读',
    '是否确认取消该条申请？取消后您可重新发起申请': '是否确认取消该条申请？取消后您可重新发起申请',
    '是否确认删除该条申请记录？': '是否确认删除该条申请记录？',
    '未读': '未读',
    '常见问题': '常见问题',
    '暂未有常见问题~': '暂未有常见问题~',
    '发送': '发送',
    '按Enter发送': '按Enter发送',
    '按Ctrl+Enter发送': '按Ctrl+Enter发送',
    '暂无消息': '暂无消息',
    '在线': '在线',
    '从对话列表进入 供应商': '从对话列表进入 供应商',
    '请选择图片类型文件': '请选择图片类型文件',
    '账号已登出': '账号已登出',
    '是否要发送剪切板的图片?': '是否要发送剪切板的图片?',
    '离线': '离线',
    '供应商产品': '供应商产品',
    '暂无供应商产品～': '暂无供应商产品～',
    '平台产品': '平台产品',
    '暂无数据～': '暂无数据～',
    '订单号/采购单号/产品名称': '订单号/采购单号/产品名称',
    '确定关闭该对话吗？': '确定关闭该对话吗？',
    '搜索最近联系人': '搜索最近联系人',
    '[图片]': '[图片]',
    '[视频]': '[视频]',
    '[文件]': '[文件]',
    '[产品]': '[产品]',
    '分类：': '分类：',
    '[订单]': '[订单]',
    '展开': '展开',
    '销量': '销量',
    '人气': '人气',
    '件相关产品': '件相关产品',
    '成交量': '成交量',
    '企业介绍': '企业介绍',
    '企业资质': '企业资质',
    '搜索新产品': '搜索新产品',
    '暂无企业介绍': '暂无企业介绍',
    '营业执照': '营业执照',
    '暂无企业动态': '暂无企业动态',
    '企业动态': '企业动态',
    '企业动态详情获取失败': '企业动态详情获取失败',
    '动态详情': '动态详情',
    '店铺评分': '店铺评分',
    '主营产品：': '主营产品：',
    '全部产品': '全部产品',
    '新品专区': '新品专区',
    '高': '高',
    '低': '低',
    '从供应商详情页进入 供应商': '从供应商详情页进入 供应商',
    '关注成功': '关注成功',
    '中': '中',
    '关注失败': '关注失败',
    '取消关注失败': '取消关注失败',
    '全部资质': '全部资质',
    '取消关注成功': '取消关注成功',
    '本店暂无产品～': '本店暂无产品～',
    '友晟': '友晟',
    '已售': '已售',
    '晨晶电子': '晨晶电子',
    '友益': '友益',
    '七星飞行': '七星飞行',
    '七星微电子': '七星微电子',
    '三水日明': '三水日明',
    '需登录才能操作': '需登录才能操作',
    '只有设计师才可以使用该功能～': '只有设计师才可以使用该功能～',
    '密码最少6位哦～': '密码最少6位哦～',
    '请输入正确的手机号': '请输入正确的手机号',
    '密码不可以有中文哦～': '密码不可以有中文哦～',
    '密码中不可以有空格哦～': '密码中不可以有空格哦～',
    '密码最多20位哦～': '密码最多20位哦～',
    '请输入正确的短信验证码': '请输入正确的短信验证码',
    '会员名不能全为数字': '会员名不能全为数字',
    '请输入正确的邮箱验证码': '请输入正确的邮箱验证码',
    '会员名须由中、英文、数字、"-"及"_"组成': '会员名须由中、英文、数字、"-"及"_"组成',
    '请输入6～20位的会员名': '请输入6～20位的会员名',
    '请输入正确的图形验证码': '请输入正确的图形验证码',
    '该功能在升级中～': '该功能在升级中～',

};
