<template>
    <div class="loader_main">
        <div class="loader">
            <div class="text">Loading...</div>
            <div class="horizontal">
                <div class="circlesup">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                <div class="circlesdwn">
                <div class="circle"></div>
                <div class="circle"></div>
                <div class="circle"></div>
                <div class="circle"></div>
                <div class="circle"></div>
                </div>
            </div>
            <div class="vertical">
                <div class="circlesup">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                <div class="circlesdwn">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
            </div>
        </div>
        <div style="text-align:center;margin:10px 0; font:normal 14px/24px 'MicroSoft YaHei';"></div>
    </div>
</template>

<script>
    /*
      HTML5 Shiv v3.7.0 | @afarkas @jdalton @jon_neal @rem | MIT/GPL2 Licensed
    */
    (function (l, f) {
        function m() { var a = e.elements; return "string" == typeof a ? a.split(" ") : a } function i(a) { var b = n[a[o]]; b || (b = {}, h++, a[o] = h, n[h] = b); return b } function p(a, b, c) { b || (b = f); if (g) return b.createElement(a); c || (c = i(b)); b = c.cache[a] ? c.cache[a].cloneNode() : r.test(a) ? (c.cache[a] = c.createElem(a)).cloneNode() : c.createElem(a); return b.canHaveChildren && !s.test(a) ? c.frag.appendChild(b) : b } function t(a, b) {
            if (!b.cache) b.cache = {}, b.createElem = a.createElement, b.createFrag = a.createDocumentFragment, b.frag = b.createFrag();
            a.createElement = function (c) { return !e.shivMethods ? b.createElem(c) : p(c, a, b) }; a.createDocumentFragment = Function("h,f", "return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(" + m().join().replace(/[\w\-]+/g, function (a) { b.createElem(a); b.frag.createElement(a); return 'c("' + a + '")' }) + ");return n}")(e, b.frag)
        } function q(a) {
            a || (a = f); var b = i(a); if (e.shivCSS && !j && !b.hasCSS) {
                var c, d = a; c = d.createElement("p"); d = d.getElementsByTagName("head")[0] || d.documentElement; c.innerHTML = "x<style>article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}</style>";
                c = d.insertBefore(c.lastChild, d.firstChild); b.hasCSS = !!c
            } g || t(a, b); return a
        } var k = l.html5 || {}, s = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i, r = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i, j, o = "_html5shiv", h = 0, n = {}, g; (function () {
            try {
                var a = f.createElement("a"); a.innerHTML = "<xyz></xyz>"; j = "hidden" in a; var b; if (!(b = 1 == a.childNodes.length)) {
                    f.createElement("a"); var c = f.createDocumentFragment(); b = "undefined" == typeof c.cloneNode ||
                        "undefined" == typeof c.createDocumentFragment || "undefined" == typeof c.createElement
                } g = b
            } catch (d) { g = j = !0 }
        })(); var e = {
            elements: k.elements || "abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output progress section summary template time video", version: "3.7.0", shivCSS: !1 !== k.shivCSS, supportsUnknownElements: g, shivMethods: !1 !== k.shivMethods, type: "default", shivDocument: q, createElement: p, createDocumentFragment: function (a, b) {
                a || (a = f);
                if (g) return a.createDocumentFragment(); for (var b = b || i(a), c = b.frag.cloneNode(), d = 0, e = m(), h = e.length; d < h; d++)c.createElement(e[d]); return c
            }
        }; l.html5 = e; q(f)
    })(this, document);
</script>

<style lang="scss" scoped>
.loader_main {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background: linear-gradient(0deg, #DEE2E7, #FAFDFF);
	color:#f2f2f2;
	font-size: 30%;
	font-weight: 100;
	font-family: 'Open Sans';
	margin:0em;
	padding:0em;
}

/* Start the loader code, first, let's align it the center of screen */
.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-mos-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align:center;
/* disable selection and cursor changes */
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor:default;
}

/* Text align it the center of screen and connect the looped animation for 2 seconds */
.text{
	position: absolute;
	left: -1.3em;
	top: -1.7em;
	-webkit-animation: text 2s infinite;
	-moz-animation: text 2s infinite;
	-moz-animation: text 2s infinite;
	-ms-animation: text 2s infinite;
	-o-animation: text 2s infinite;
	animation: text 2s infinite;
}

/* Set for the first layer vertical position */
.vertical{
	position:absolute;
	top: -1.84em;
	left: -0.4em;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

/* Set for the second layer horizontal position */
.horizontal{
	position:absolute;
	top:0em;
	left:0em;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}

/* The next two classes do mirror for animation */
.circlesup{
	position:absolute;
	top: -4.7em;
	right: 12.1em;
}

.circlesdwn{
	position:absolute;
	top:2.5em;
	right:-13.5em;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

/* Create a container for our circles, rotate it 45 degrees and set animation*/
.circle {
	position: absolute;
	width: 15em;
	height: 15em;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-animation: orbit 2s infinite;
	-moz-animation: orbit 2s infinite;
	-moz-animation: orbit 2s infinite;
	-ms-animation: orbit 2s infinite;
	-o-animation: orbit 2s infinite;
	animation: orbit 2s infinite;
	z-index:5;
}

/* Style's of our circles */
.circle:after {
	content: '';
	position: absolute;
	width: 2em;
	height: 2em;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
	background: #6079f5; /* Pick a color 1*/
}

.circle:nth-child(2) {
	-webkit-animation-delay: 100ms;
	-moz-animation-delay: 100ms;
	-ms-animation-delay: 100ms;
	-o-animation-delay: 100ms;
	animation-delay: 100ms;
	z-index:4;
}

.circle:nth-child(2):after  {
	background: #6186FB; /* Pick a color 2*/
	-webkit-transform: scale(0.8,0.8);
	-moz-transform: scale(0.8,0.8);
	-ms-transform: scale(0.8,0.8);
	-o-transform: scale(0.8,0.8);
	transform: scale(0.8,0.8);
}

.circle:nth-child(3) {
	-webkit-animation-delay: 225ms;
	-moz-animation-delay: 225ms;
	-ms-animation-delay: 225ms;
	-o-animation-delay: 225ms;
	animation-delay: 225ms;
	z-index:3;
}

.circle:nth-child(3):after  {
	background: #759AFC; /* Pick a color 3*/
	-webkit-transform: scale(0.6,0.6);
	-moz-transform: scale(0.6,0.6);
	-ms-transform: scale(0.6,0.6);
	-o-transform: scale(0.6,0.6);
	transform: scale(0.6,0.6);
}

.circle:nth-child(4) {
	-webkit-animation-delay: 350ms;
	-moz-animation-delay: 350ms;
	-ms-animation-delay: 350ms;
	-o-animation-delay: 350ms;
	animation-delay: 350ms;
	z-index:2;
}

.circle:nth-child(4):after  {
	background: #7CA0FD; /* Pick a color 4*/
	-webkit-transform: scale(0.4,0.4);
	-moz-transform: scale(0.4,0.4);
	-ms-transform: scale(0.4,0.4);
	-o-transform: scale(0.4,0.4);
	transform: scale(0.4,0.4);
}


.circle:nth-child(5) {
	-webkit-animation-delay: 475ms;
	-moz-animation-delay: 475ms;
	-ms-animation-delay: 475ms;
	-o-animation-delay: 475ms;
	animation-delay: 475ms;
	z-index:1;
}

.circle:nth-child(5):after  {
	background: #93B8FE; /* Pick a color 5*/
	-webkit-transform: scale(0.2,0.2);
	-moz-transform: scale(0.2,0.2);
	-ms-transform: scale(0.2,0.2);
	-o-transform: scale(0.2,0.2);
	transform: scale(0.2,0.2);
}

/* Animation keys */
@-webkit-keyframes orbit {
	0%		{ -webkit-transform:rotate(45deg); }

	5%		{ -webkit-transform:rotate(45deg);
			  -webkit-animation-timing-function: ease-out; }

	70%		{ -webkit-transform:rotate(405deg);
			  -webkit-animation-timing-function: ease-in; }

	100%	{ -webkit-transform:rotate(405deg); }
}

@-moz-keyframes orbit {
	0%		{ -moz-transform:rotate(45deg); }

	5%		{ -moz-transform:rotate(45deg);
			  -moz-animation-timing-function: ease-out; }

	70%		{ -moz-transform:rotate(405deg);
			  -moz-animation-timing-function: ease-in; }

	100%	{ -moz-transform:rotate(405deg); }
}
@-ms-keyframes orbit {
	0%		{ -ms-transform:rotate(45deg); }

	5%		{ -ms-transform:rotate(45deg);
			  -ms-animation-timing-function: ease-out; }

	70%		{ -ms-transform:rotate(405deg);
			  -ms-animation-timing-function: ease-in; }

	100%	{ -ms-transform:rotate(405deg); }
}

@-o-keyframes orbit {
	0%		{ -o-transform:rotate(45deg); }

	5%		{ -o-transform:rotate(45deg);
			  -o-animation-timing-function: ease-out; }

	70%		{ -o-transform:rotate(405deg);
			  -o-animation-timing-function: ease-in; }

	100%	{ -o-transform:rotate(405deg); }
}

@keyframes orbit {
	0%		{ transform:rotate(45deg); }

	5%		{ transform:rotate(45deg);
			  animation-timing-function: ease-out; }

	70% 	{ transform:rotate(405deg);
			  animation-timing-function: ease-in; }

	100%	{ transform:rotate(405deg); }
}


@-webkit-keyframes text {
	0%		{-webkit-transform:scale(0.2,0.2);
			 -webkit-animation-timing-function: ease-out; }

	40%,60%{ -webkit-transform:scale(1,1);
			  -webkit-animation-timing-function: ease-out;  }

	70%,100%{ -webkit-transform:scale(0.2,0.2);  }
}

@-moz-keyframes text {
	0%		{-moz-transform:scale(0.2,0.2);
			 -moz-animation-timing-function: ease-out; }

	50%		{ -moz-transform:scale(1,1);
			  -moz-animation-timing-function: ease-out;  }

	60%		{ -moz-transform:scale(1,1);
			-moz-animation-timing-function: ease-out;  }

	100%	{ -moz-transform:scale(0.2,0.2);  }
}

@-mos-keyframes text {
	0%		{-mos-transform:scale(0.2,0.2);
			 -mos-animation-timing-function: ease-out; }

	50%		{ -mos-transform:scale(1,1);
			  -mos-animation-timing-function: ease-out;  }

	60%		{ -mos-transform:scale(1,1);
			  -mos-animation-timing-function: ease-out;  }

	100%	{ -mos-transform:scale(0.2,0.2);  }
}

@-o-keyframes text {
	0%		{ -o-transform:scale(0.2,0.2);
			  -o-animation-timing-function: ease-out; }

	50%		{ -o-transform:scale(1,1);
			  -o-animation-timing-function: ease-out;  }

	60%		{ -o-transform:scale(1,1);
			  -o-animation-timing-function: ease-out;  }

	100%	{ -o-transform:scale(0.2,0.2);  }
}

@keyframes text {
	0%		{ transform:scale(0.2,0.2);
			  animation-timing-function: ease-out; }

	50%		{ transform:scale(1,1);
			  animation-timing-function: ease-out;  }

	60%		{ transform:scale(1,1);
			  animation-timing-function: ease-out;  }

	100%	{ transform:scale(0.2,0.2);  }
}

</style>
